import React from "react";
import "./technology.scss";

const renderItem = info => {
  if (!info) return null;
  return (
    <div key={info.name} className="stack-item">
      <h4 className="title">{info.name}:</h4>
      <div>
        {info.list.map((elem, id, array) => {
          return (
            <span key={id}>
              <span className="tech-name">{elem} </span>
              {id !== array.length - 1 ? <span className="red">/ </span> : null}
            </span>
          );
        })}
      </div>
    </div>
  );
};

const renderList = list => {
  if (!list) return null;
  return (
    <div className={"stack-column"}>{list.map(elem => renderItem(elem))}</div>
  );
};

const Technology = ({ stacks }) => {
  return stacks ? (
    <section className="work-page-technology-container">
      <div className="title-container">
        <img src="/svg/section-red-line-69x1.svg" alt="red-title-line" />
        <h2>{"Technology stack"}</h2>
      </div>
      <div className="stack-container">
        {renderList(stacks[0])}
        {renderList(stacks[1])}
      </div>
    </section>
  ) : null;
};

export default Technology;
