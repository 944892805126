import React, { useState, useEffect } from "react";
import { addField } from "ra-core";
import { withStyles, TextField, Button } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import classnames from "classnames";
import { REDUX_FORM_NAME } from "react-admin";

const styles = theme => ({
  wrapper: {
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 18,
    "& > span": {
      color: "#000"
    },
    "& textarea": {
      minHeight: "42px !important"
    },
    "& > div": {
      border: "1px solid rgba(0, 0, 0, 0.42)",
      borderRadius: 4,
      marginTop: 6,
      padding: "0 10px",
      "& > div": {
        border: "1px solid transparent"
      }
    },
    "& > p": {
      margin: 0,
      fontSize: "0.75rem",
      marginTop: 8,
      minHeight: "1em",
      lineHeight: "1em",
      color: theme.palette.error.main
    },
    "&:focus-within": {
      "& > div": {
        border: `2px solid ${theme.palette.primary.dark}`,
        "& > div": {
          border: "none"
        }
      }
    },
    "&:hover": {
      "& > div": {
        border: `2px solid rgba(0, 0, 0, 0.87)`,
        "& > div": {
          border: "none"
        }
      }
    }
  },
  a: {
    marginBottom: 30
  },
  error: {
    "& > div": {
      border: `2px solid ${theme.palette.error.main} !important`,
      "& > div": {
        border: "none"
      }
    },
    "&:hover": {
      "& > div": {
        border: `2px solid ${theme.palette.error.main}`,
        "& > div": {
          border: "none"
        }
      }
    }
  },
  removeQuestionButton: {
    padding: 0,
    minWidth: "auto",
    marginRight: 10,
    minHeight: "auto"
  },
  removeIcon: {
    fontSize: 16,
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: 4
  }
});

const CustomOutlinedTextInput = ({
  classes,
  FAQList,
  change,
  meta: { touched, error },
  source,
  kind,
  order,
  label,
  removeQuestion
}) => {
  const [val, setVal] = useState(FAQList.length ? FAQList[order][kind] : "");
  const handleChange = e => {
    setVal(e.target.value);
    change(REDUX_FORM_NAME, source, e.target.value);
  };

  return (
    <div
      className={classnames(
        classes.wrapper,
        !!(touched && error) ? classes.error : null,
        classes[kind]
      )}
    >
      {removeQuestion && (
        <Button
          color="primary"
          className={classes.removeQuestionButton}
          onClick={() => removeQuestion(order)}
        >
          <Remove className={classes.removeIcon} />
        </Button>
      )}
      <span>{label}</span>
      <div>
        <TextField
          fullWidth
          multiline
          onChange={handleChange}
          value={val}
          InputProps={{ disableUnderline: true }}
        />
      </div>
      {!!(touched && error) && <p>{error}</p>}
    </div>
  );
};

export const OutlinedTextInput = React.memo(
  withStyles(styles)(addField(CustomOutlinedTextInput))
);
