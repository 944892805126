import React, { useState } from "react";
import { withStyles, Checkbox, Button } from "@material-ui/core";
import { REDUX_FORM_NAME } from "react-admin";
import { Add } from "@material-ui/icons";
import { OutlinedTextInput } from "./QAOutlinedTextInput";
import withTranslate from "ra-core/esm/i18n/translate";
import {Editor} from "../../common/Editor";

const styles = theme => ({
  container: {
    marginLeft: 32
  },
  addQuestionButton: {
    textTransform: "none",
    fontSize: 16
  },
  addIcon: {
    fontSize: 16,
    marginRight: 10,
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: 4
  }
});

const FAQ = ({ change, classes, source, record, values, translate }) => {
  const [isShowFAQ, setIsShowFAQ] = useState(record[source]);
  const [FAQList, setFAQList] = useState(record.FAQList || []);
  const handleChange = () => {
    change(REDUX_FORM_NAME, source, !isShowFAQ);
    if (values && values.FAQList) {
      setFAQList([...values.FAQList]);
    }
    setIsShowFAQ(!isShowFAQ);
  };
  const addQuestion = () => {
    if (values && values.FAQList) {
      change(REDUX_FORM_NAME, "FAQList", [...values.FAQList, { q: "", a: "" }]);
      setFAQList([...values.FAQList, { q: "", a: "" }]);
    } else {
      change(REDUX_FORM_NAME, "FAQList", [{ q: "", a: "" }]);
      setFAQList([{ q: "", a: "" }]);
    }
  };

  const removeQuestion = i => {
    let newFAQList = [];
    if (values && values.FAQList) {
      newFAQList = values.FAQList;
    } else {
      newFAQList = FAQList;
    }
    newFAQList.splice(i, 1);
    change(REDUX_FORM_NAME, "FAQList", [...newFAQList]);
    setFAQList([...newFAQList]);
  };

  return (
    <>
      <div>
        <Checkbox
          checked={isShowFAQ === true}
          onClick={e => handleChange(e)}
          color="primary"
        />
        <label>{translate("ra.pages.articles.fields.add_faq")}</label>
      </div>
      <div className={classes.container}>
        {FAQList.map((el, i) => (
          <div key={el.q + el.a + i}>
            <OutlinedTextInput
              removeQuestion={removeQuestion}
              FAQList={FAQList}
              source={`FAQList[${i}].q`}
              kind="q"
              order={i}
              label={translate("ra.pages.articles.fields.question") + " *"}
              change={change}
            />
            <Editor
              record={FAQList[i].a}
              label={translate("ra.pages.articles.fields.answer") + " *"}
              change={change}
              forFAQ={{
                source: `FAQList`,
                order: i,
                kind: 'a'
              }}
            />
          </div>
        ))}
        {FAQList.length < 10 && (
          <Button
            color="primary"
            className={classes.addQuestionButton}
            onClick={addQuestion}
          >
            <Add className={classes.addIcon} />{" "}
            {translate("ra.pages.articles.actions.add_question")}
          </Button>
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(withTranslate(FAQ));
