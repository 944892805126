import React from "react";
import { MenuItemLink, withTranslate } from "react-admin";
import {
  SpeakerNotes,
  PermMedia,
  Mail,
  SupervisorAccount,
  Devices,
  ImportantDevices,
  FontDownload,
  FileCopy,
  Assessment
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  active: {
    color: theme.palette.primary.main
  }
});

const Menu = ({ classes, translate }) => {
  return (
    <>
      <MenuItemLink
        to="/articles"
        primaryText={translate("ra.menu.articles")}
        leftIcon={<SpeakerNotes />}
        classes={{ active: classes.active }}
      />
      <MenuItemLink
        to="/work-cases"
        primaryText={translate("ra.menu.works")}
        leftIcon={<ImportantDevices />}
        classes={{ active: classes.active }}
      />
      <MenuItemLink
        to="/infographics"
        primaryText={translate("ra.menu.infographics")}
        leftIcon={<Assessment />}
        classes={{ active: classes.active }}
      />
      <MenuItemLink
        to="/media"
        primaryText={translate("ra.menu.media")}
        leftIcon={<PermMedia />}
        classes={{ active: classes.active }}
      />
      <MenuItemLink
        to="/emails"
        primaryText={translate("ra.menu.emails")}
        leftIcon={<Mail />}
        classes={{ active: classes.active }}
      />
      <MenuItemLink
        to="/authors"
        primaryText={translate("ra.menu.authors")}
        leftIcon={<SupervisorAccount />}
        classes={{ active: classes.active }}
      />
      <MenuItemLink
        to="/s3file"
        primaryText={translate("ra.menu.files")}
        leftIcon={<FileCopy />}
        classes={{ active: classes.active }}
      />
      <MenuItemLink
        to="/seo"
        primaryText={translate("ra.menu.seo")}
        leftIcon={<Devices />}
        classes={{ active: classes.active }}
      />
      <MenuItemLink
        to="/robots"
        primaryText={translate("ra.menu.robots")}
        leftIcon={<FontDownload />}
        classes={{ active: classes.active }}
      />
    </>
  );
};

export default withStyles(styles)(withTranslate(Menu));
