import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from "react-admin";
import AuthQueryProvider from "./authQueryProvider";

export default async (type, params) => {
  if (type === AUTH_LOGIN) {
    let data = await AuthQueryProvider.execute("/login", {
      login: params.username,
      password: params.password
    });
    if (data.message) return Promise.reject();
    localStorage.setItem("login", params.username);
    localStorage.setItem("token", data.token);
    localStorage.setItem("refreshToken", data.refreshToken);
    return Promise.resolve();
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const { status } = params;

    if (status === 401) {
      localStorage.removeItem("login");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      return Promise.reject();
    }

    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const token = localStorage.getItem("token");
    if (!token) {
      return Promise.reject();
    }
    return Promise.resolve();
  }

  return Promise.reject("Unknown method");
};