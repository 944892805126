import { IconButton, Menu } from "@material-ui/core";
import { AccountCircle, Settings } from "@material-ui/icons";
import React, { useState } from "react";
import { AppBar, MenuItemLink, withTranslate } from "react-admin";

const CustomUserMenu = React.memo(
  withTranslate(({ logout, translate }) => {
    const [anchorEl, setAnchor] = useState(null);

    const handleClick = event => {
      setAnchor(event.currentTarget);
    };

    const handleClose = () => {
      setAnchor(null);
    };

    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <AccountCircle style={{ color: "#fff" }} />
        </IconButton>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItemLink
            onClick={handleClose}
            to="/settings"
            primaryText={translate("ra.menu.settings")}
            leftIcon={<Settings />}
          />
          {logout}
        </Menu>
      </>
    );
  })
);

export const CustomAppBar = props => (
  <AppBar
    {...props}
    userMenu={<CustomUserMenu logout={props.logout} />}
  ></AppBar>
);
