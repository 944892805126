import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import Edit from "./Edit";

const styles = theme => ({
  wrapper: {
    padding: 10
  },
  media: {
    margin: 2,
    borderRadius: 4,
    height: 150,
    width: 200,
    display: "inline-block",
    boxSizing: "border-box",
    border: `2px solid transparent`,
    background: "#EEF6FE",
    "&:hover": {
      cursor: "pointer",
      border: `2px solid rgba(0, 0, 0, 0.87)`
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "scale-down"
    },
    "& video": {
      width: "100%",
      height: "100%",
      objectFit: "scale-down"
    }
  }
});

const CustomMediaField = React.memo(({ record, handleClickOpen, classes }) => {
  return (
    <div className={classes.media} onClick={() => handleClickOpen(record)}>
      {record.fileType === "video/mp4" ? (
        <video>
          <source
            src={"https://img.orangesoft.co/media/" + record.url}
            type="video/mp4"
          />
        </video>
      ) : (
        <img
          src={"https://img.orangesoft.co/media/" + record.url}
          alt={record.altName}
        />
      )}
    </div>
  );
});

const UnconnectedMediaGrid = ({ ids, data, classes }) => {
  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState({});

  const handleClickOpen = record => {
    setOpen(true);
    setRecord({
      ...record,
      date: new Date(record.date).toLocaleString(),
      url: "https://img.orangesoft.co/media/" + record.url
    });
  };

  return (
    <>
      <div className={classes.wrapper}>
        {ids.map(id => (
          <CustomMediaField
            classes={classes}
            key={id}
            record={data[id]}
            handleClickOpen={handleClickOpen}
          />
        ))}
      </div>
      {open && <Edit record={record} setOpen={setOpen} setRecord={setRecord} />}
    </>
  );
};

export const MediaGrid = withStyles(styles)(UnconnectedMediaGrid);
