import React from "react";
import Toolbar from "@material-ui/core/Toolbar";

import { List, CreateButton } from "react-admin";
import { withStyles } from "@material-ui/core";
import { MediaGrid } from "./MediaGrid";
import { withTranslate } from "ra-core";

const styles = theme => ({
  list: {
    "& > div": {
      boxShadow: "none"
    },
    "& div[aria-haspopup='true']": {
      background: "#EEF6FE",
      borderRadius: 4
    }
  },
  toolbar: {
    right: 0,
    position: "absolute",
    padding: 0,
    "& a": {
      border: "1px solid rgba(232, 85, 31, 0.5)"
    }
  }
});

const MediaActions = ({ basePath, translate, classes }) => (
  <Toolbar className={classes.toolbar}>
    <CreateButton
      basePath={basePath}
      label={translate("ra.pages.media.actions.add")}
    />
  </Toolbar>
);

const MediaList = ({ classes, translate, ...props }) => {
  return (
    <List
      {...props}
      exporter={false}
      actions={<MediaActions classes={classes} translate={translate} />}
      perPage={25}
      className={classes.list}
      title={translate("ra.menu.media")}
    >
      <MediaGrid />
    </List>
  );
};

export default withStyles(styles)(withTranslate(MediaList));
