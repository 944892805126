import englishMessages from "../i18n/en";
import russianMessages from "../i18n/ru";

const messages = {
  en: englishMessages,
  ru: russianMessages
};
export let lang = "en";
export default locale => {
  lang = locale;
  return messages[locale];
};
