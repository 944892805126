import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { addField } from 'ra-core';
import TextField from '@material-ui/core/TextField';
import * as ReactColor from 'react-color';

import { REDUX_FORM_NAME } from 'react-admin';
import { Label } from '../Label';
import { connect } from 'react-redux';

require('./ColorInput.css');

const ColorFieldComponent = ({ source, record = {}, className }) =>
  (
    <div style={{ display: 'flex' }}>
      <div style={{
        width: '20px',
        height: '20px',
        background: record[source],
        marginRight: '5px',
      }}
      />
      <span className={className}>{record[source]}</span>
    </div>
  );

ColorFieldComponent.propTypes = {
  addLabel: PropTypes.bool,
  className: PropTypes.string,
  elStyle: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const PureTextField = React.memo(ColorFieldComponent);

PureTextField.defaultProps = {
  addLabel: true,
};

class ColorInputComponent extends React.Component {
  state = {
    show: false
  };

  handleOpen = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });
  handleChange = ({ hex }) => {
    this.props.input.onChange(hex);
    this.forceUpdate();
  };

  render() {
    const {
      label,
      source,
      meta,
      className,
      options,
      picker,
      input,
      resource,
      helperText,
      isRequired,
    } = this.props;

    const {
      touched,
      error,
    } = meta;

    const Picker = ReactColor[`${picker}Picker`];

    return (
      <div>
        <TextField
          {...input}
          margin="normal"
          onFocus={this.handleOpen}
          // label={
          //   <FieldTitle
          //     label={label}
          //     source={source}
          //     resource={resource}
          //     isRequired={isRequired}
          //   />
          // }
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <Label val={label} />
            )
          }}
          error={!!(touched && error)}
          helperText={touched && error || helperText}
          className={className}
          fullWidth
        />
        {
          this.state.show?
            <div className="ColorInput-popup">
              <div
                className="ColorInput-cover"
                onClick={this.handleClose}
              />
              <Picker
                {...options}
                color={input.value}
                onChange={this.handleChange}
              />
            </div>
            : null
        }
      </div>
    )
  }
};

ColorInputComponent.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
  input: PropTypes.object,
  helperText: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  className: PropTypes.string,
  picker: (props, propName, componentName) =>
    !ReactColor[`${props[propName]}Picker`] &&
    new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`.`)
};

ColorInputComponent.defaultProps = {
  picker: 'Chrome',
  options: {
    disableAlpha: true
  },
};

export const ColorField = PureTextField;
export const ColorInput = addField(ColorInputComponent);

const CustomColorInputComponent = ({ change, source, label, formValues, ...props }) => {
  const sourceValue = (formValues && formValues[source]) || '';

  useEffect(() => {
    if (sourceValue) {
      const newValue = sourceValue.startsWith('#') ? sourceValue : `#${ sourceValue }`;
      change(REDUX_FORM_NAME, source, newValue);
    }
  }, [sourceValue]);

  return (
    <ColorInput
      source={ source }
      label={label}
      { ...props }
    />
  );
};

const _mapStateToProps = state => {
  return {
    formValues: state.form['record-form'].values
  };
};

export const CustomColorInput = connect(_mapStateToProps)(CustomColorInputComponent);
