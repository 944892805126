import { fetchUtils } from "react-admin";
import { addBearer } from "../helpers/dataHelpers";
import getUrl from "../common/getUrl";

const httpClient = async (url, options = {}) => {
  return fetchUtils
    .fetchJson(getUrl() + url, await addBearer(options))
    .catch(async error => {
      if (error.status === 401) {
        alert("401");
        //refresh token
        //return await httpClient(url, options);
      }

      return Promise.reject(error);
    });
};

export default httpClient;
