import React, { useState, useEffect } from "react";
import { List, Datagrid, TextField, CreateButton } from "react-admin";
import classnames from "classnames";
import { withStyles, Toolbar, Checkbox } from "@material-ui/core";
import DeleteButton from "../../common/DeleteButton";
import { styles } from "../../common/listStyles";
import httpClient from "../../providers/httpClient";
import { choices } from "./worksServices";
import { CustomDateTimeField } from "../../common/CustomDateField";
import { statuses } from "../../common/statuses";
import { withTranslate } from "ra-core";

const ArticlesActions = ({ basePath, translate, classes }) => (
  <Toolbar className={classes.toolbar}>
    <CreateButton
      basePath={basePath}
      label={translate("ra.pages.works.actions.add")}
    />
  </Toolbar>
);

const CustomArrayField = ({ record, source, classes }) => {
  return (
    <span className={classnames(classes.tdStyle)}>
      {record[source] && record[source].map(el => choices[el]).join(", ")}
    </span>
  );
};

const CustomStatusField = ({ record, source, classes, translate }) => {
  return (
    <span className={classnames(classes.tdStyle, classes.capitalize)}>
      {statuses(translate)[record[source]]}
    </span>
  );
};

const CustomDatagrid = ({ classes, translate, ...props }) => {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data, setData]);

  return (
    <Datagrid
      rowClick="edit"
      className={classes.tableWrapper}
      {...props}
      data={data}
    >
      <TextField
        label={translate("ra.pages.works.fields.title")}
        source="title"
        sortable={false}
      />
      <CustomArrayField
        label={translate("ra.pages.works.fields.services")}
        source="services"
        sortable={false}
        classes={classes}
      />
      <TextField
        label={translate("ra.pages.works.fields.priority")}
        source="priority"
        sortable={false}
        classes={classes}
      />
      <CustomDateTimeField
        label={translate("ra.pages.works.fields.date")}
        source="date"
        sortable={false}
        classes={classes}
      />
      <CustomStatusField
        label={translate("ra.pages.works.fields.status")}
        source="status"
        sortable={false}
        translate={translate}
        classes={classes}
      />
      <DeleteButton />
    </Datagrid>
  );
};
const ArticlesList = ({ classes, translate, ...props }) => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      actions={<ArticlesActions classes={classes} translate={translate} />}
      className={classes.list}
      title={translate("ra.menu.works")}
    >
      <CustomDatagrid classes={classes} translate={translate} />
    </List>
  );
};

export default withStyles(styles)(withTranslate(ArticlesList));
