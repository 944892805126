export const categoriesChoices = translate => [
  {
    id: "client_guides",
    name: translate("ra.pages.articles.categories.client_guides")
  },
  { id: "tech", name: translate("ra.pages.articles.categories.tech") },
  { id: "design", name: translate("ra.pages.articles.categories.design") },
  { id: "startups", name: translate("ra.pages.articles.categories.startups") },
  {
    id: "orangesoft",
    name: translate("ra.pages.articles.categories.orangesoft")
  }
];

export const categories = translate => ({
  client_guides: translate("ra.pages.articles.categories.client_guides"),
  tech: translate("ra.pages.articles.categories.tech"),
  design: translate("ra.pages.articles.categories.design"),
  startups: translate("ra.pages.articles.categories.startups"),
  orangesoft: translate("ra.pages.articles.categories.orangesoft")
});
