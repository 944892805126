export const validateArticle = translate => values => {
  const requiredArr = value =>
    value && value.length ? undefined : translate("ra.validation.required");
  const validTitle = value =>
    value.match(/^[\sa-zA-Z0-9?:!,.%'$&—\[\]+-]+$/)
      ? undefined
      : translate("ra.validation.only_latin");
  const maxLength = max => value =>
    value.length <= max
      ? undefined
      : translate("ra.validation.maxLength", { max });
  const requiredText = value =>
    value && value.length ? undefined : translate("ra.validation.required");
  const validURL = value =>
    value.match(/^[a-z0-9-]+$/)
      ? undefined
      : translate("ra.validation.url_not_valid");
  const required = translate("ra.validation.required");

  const errors = {};
  if (values.status === "published") {
    //categories
    if (requiredArr(values.categories)) {
      errors.categories = [requiredArr(values.categories)];
    }
    //author
    if (!values.author) {
      errors.author = [required];
    }
    //text
    if (requiredText(values.text)) {
      errors.text = [requiredText(values.text)];
    }
    //description
    if (!values.description) {
      errors.description = [required];
    }
    //previewFeature
    if (!values.previewFeature) {
      errors.previewFeature = [required];
    }
    //heroFeature
    if (!values.heroImageFeature) {
      errors.heroImageFeature = [required];
    }
    //seoTitle
    if (!values.seoTitle) {
      errors.seoTitle = [required];
    }
  }

  //title
  if (!values.title) {
    errors.title = [required];
  } else if (validTitle(values.title)) {
    errors.title = [validTitle(values.title)];
  } else if (maxLength(100)(values.title)) {
    errors.title = [maxLength(100)(values.title)];
  }
  //url
  if (!values.url) {
    errors.url = [required];
  } else if (validURL(values.url)) {
    errors.url = [validURL(values.url)];
  }
  //description
  if (values.description) {
    if (maxLength(600)(values.description)) {
      errors.description = [maxLength(600)(values.description)];
    }
  }
  //seoTitle
  if (values.seoTitle) {
    if (maxLength(160)(values.seoTitle)) {
      errors.seoTitle = [maxLength(160)(values.seoTitle)];
    }
  }

  //FAQList
  if (values.FAQList) {
    errors.FAQList = [];
    values.FAQList.map(({ q, a }, i) => {
      errors.FAQList.push({});
      if (!q) {
        errors.FAQList[i].q = [required];
      } else if (maxLength(300)(q)) {
        errors.FAQList[i].q = [maxLength(300)(q)];
      }
      if (!a) {
        errors.FAQList[i].a = [required];
      } else if (maxLength(1000)(a)) {
        errors.FAQList[i].a = [maxLength(1000)(a)];
      }
    });
  }

  return errors;
};
