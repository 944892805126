import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  fetchUtils
} from "react-admin";
import { stringify } from "query-string";
import { addBearer } from "../helpers/dataHelpers";
import getUrl from "../common/getUrl";

const API_URL = getUrl();

const customAuthorAction = async (data, method, id) => {
  const formData = new FormData();
  Object.keys(data).map(el => {
    if (el === "photo") {
      if (typeof data[el] !== "string")
        formData.append(el, data[el].rawFile, data[el].title);
    } else {
      formData.append(el, data[el]);
    }
    return el;
  });

  return {
    url: `${API_URL}/authors${method === "PUT" ? "/" + id : ""}`,
    options: await addBearer({
      method,
      body: formData
    })
  };
};

export const customMediaAction = async data => {
  const formData = new FormData();
  Object.keys(data).map(el => {
    if (el === "photo") {
      formData.append(el, data[el].rawFile, data[el].title);
    } else {
      formData.append(el, data[el]);
    }
    return el;
  });

  return {
    url: `${API_URL}/media`,
    options: await addBearer({
      method: "POST",
      body: formData
    })
  };
};

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = async (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const query = {
        offset: JSON.stringify(page),
        limit: JSON.stringify(perPage)
      };

      if (params.filter.q) {
        query.search = params.filter.q;
      }

      return {
        url: `${API_URL}/${resource}?${stringify(query)}`,
        options: await addBearer({ method: "GET" })
      };
    }
    case GET_ONE:
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: await addBearer({ method: "GET" })
      };
    case GET_MANY: {
      const query = {
        filter: JSON.stringify({ id: params.ids })
      };
      return {
        url: `${API_URL}/${resource}?${stringify(query)}`,
        options: await addBearer({ method: "GET" })
      };
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const query = {
        offset: JSON.stringify(page),
        limit: JSON.stringify(perPage)
      };
      return {
        url: `${API_URL}/${resource}?${stringify(query)}`,
        options: await addBearer({ method: "GET" })
      };
    }
    case UPDATE:
      if (resource === "authors") {
        return await customAuthorAction(params.data, "PUT", params.id);
      }

      const method = resource.includes('/rollback') ? 'POST' : 'PUT';

      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: await addBearer({
          method,
          body: JSON.stringify(params.data)
        })
      };
    case CREATE:
      if (resource === "authors") {
        return await customAuthorAction(params.data, "POST");
      }
      if (resource === "media") {
        return await customMediaAction(params.data);
      }
      return {
        url: `${API_URL}/${resource}`,
        options: await addBearer({
          method: "POST",
          body: JSON.stringify(params.data)
        })
      };
    case DELETE:
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: await addBearer({ method: "DELETE" })
      };
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (
  response,
  type,
  resource,
  params
) => {
  const { headers, json } = response;
  if (!json) {
    return {
      data: [],
      total: parseInt(headers.get("X-Pagination-Total-Count"), 10)
    };
  }
  switch (type) {
    case GET_LIST: {
      return {
        data: json,
        total: parseInt(headers.get("X-Pagination-Total-Count"), 10)
      };
    }
    case CREATE:
      return { data: { ...params.data, id: json.id } };
    default:
      return { data: json };
  }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default async (type, resource, params) => {
  if (['infographics', 'articles'].includes(resource)) {
    resource = `admin/${resource}`;
  }
  if (params.rollback) {
    resource += '/rollback';
  }

  const { fetchJson } = fetchUtils;
  const { url, options } = await convertDataProviderRequestToHTTP(
    type,
    resource,
    params
  );
  return fetchJson(url, options).then(response =>
    convertHTTPResponseToDataProvider(response, type, resource, params)
  );
};
