export const services = [
  { id: "android", name: "Android" },
  { id: "ios", name: "iOS" },
  { id: "ui/ux", name: "UI/UX" },
  { id: "web", name: "Web" },
  { id: "game_development", name: "Game Development" }
];

export const choices = {
  android: "Android",
  ios: "iOS",
  "ui/ux": "UI/UX",
  web: "Web",
  game_development: "Game Development"
};
