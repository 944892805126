import Command from "@ckeditor/ckeditor5-core/src/command";
import getModalData from "./optionsmodal";

export default class InsertBannerCommand extends Command {
  execute() {
    let callbackCreate = (data) => {
      this.editor.model.change((writer) => {
        this.editor.model.insertContent(createBanner(writer, data));
      });
      setTimeout(() => {
        colorizeBanner(data);
      }, 5);
      console.log(this.editor.getData());
    };
    let callbackUpdate = (data) => {
      // this.editor.model.change((writer) => {
      //   this.editor.model.insertContent(createBanner(writer, data));
      // });
      let editorData = this.editor.getData();
      editorData = editorData.replace(
        /<div class="banner-hide-content">.+<\/div>/,
        `<div class="banner-hide-content">${data.join(";")}</div>`
      );
      this.editor.setData(editorData);
      setTimeout(() => {
        colorizeBanner(data);
      }, 5);
    };
    const dataElem = document.querySelector(
      "div.ck-editor__main .banner-hide-content"
    );
    if (!dataElem) {
      getModalData(callbackCreate);
    } else {
      getModalData(callbackUpdate, dataElem.innerText.split(";"));
    }
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      "banner"
    );

    setTimeout(() => {
      const dataElem = document.querySelectorAll(
        "div.ck-editor__main .banner-hide-content"
      );
      if (dataElem.length && dataElem[dataElem.length - 1].innerText) {
        colorizeBanner(dataElem[dataElem.length - 1].innerText.split(";"));
      }
    }, 5);

    this.isEnabled = allowedIn !== null;
  }
}

function colorizeBanner(data) {
  const banners = document.querySelectorAll("div.ck-editor__main .banner");
  if (banners.length) {
    for (let banner of banners) {
      banner.style = `background:${data[0]};`;
    }
  }

  const buttons = document.querySelectorAll(
    "div.ck-editor__main  .banner-button"
  );
  if (buttons.length) {
    for (let button of buttons) {
      button.style = `background:${data[1]}; color:${data[2]}`;
    }
  }
}

function createBanner(writer, data) {
  const banner = writer.createElement("banner");
  const bannerTitle = writer.createElement("bannerTitle");
  const bannerDescription = writer.createElement("bannerDescription");
  const bannerButton = writer.createElement("bannerButton");
  const bannerHideContent = writer.createElement("bannerHideContent");
  writer.appendText("6 Free Blog Templates", bannerTitle);
  writer.appendText(
    "Save time creating blog posts with these free templates",
    bannerDescription
  );
  writer.appendText("Download now", bannerButton);
  writer.appendText(data.join(";"), bannerHideContent);

  writer.append(bannerTitle, banner);
  writer.append(bannerDescription, banner);
  writer.append(bannerButton, banner);
  writer.append(bannerHideContent, banner);

  return banner;
}
