import React, { useState, useEffect } from "react";
import {
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { DateTimeInput } from "../../common/DateInput";
import { Editor } from "../../common/Editor";
import { Typography, CardContent, Card } from "@material-ui/core";
import classnames from "classnames";
import { Label } from "../../common/Label";
import { OutlinedTextInput } from "../../common/OutlinedTextInput";
import SelectArrayInput from "../../common/SelectArrayInput";
import { validateArticle } from "./validateArticle";
import CustomToolbar from "./CustomToolbar";
import { categoriesChoices } from "./articlesCategories";
import FAQ from "./FAQ";
import { choices } from "../../common/statuses";
import VersionHistory from "./VersionHistory";
import ImagePicker from "../../common/CustomImagePicker";


const ReadTime = ({ record, classes, translate }) => {
  return (
    <Typography
      variant="subheading"
      component="h4"
      className={classes.readtime}
    >
      {`${translate("ra.pages.articles.fields.read_time")}  ${record.readTime}`}
    </Typography>
  );
};

const Form = ({ classes, change, translate, values, ...props }) => {
  const [req, setReq] = useState(false);

  useEffect(() => {
    if (values && values.status === "published") {
      setReq(true);
    } else {
      setReq(false);
    }
  }, [values, setReq]);

  return (
    <SimpleForm
      {...props}
      toolbar={<CustomToolbar values={values} />}
      validate={validateArticle(translate)}
    >
      <TextInput
        source="title"
        fullWidth
        className={classes.noLabel}
        placeholder={translate("ra.pages.articles.placeholders.title")}
        InputProps={{
          startAdornment: (
            <Label val={translate("ra.pages.articles.fields.title") + " *"} />
          )
        }}
      />
      {props.record && props.record.id && (
        <TextInput
          source="url"
          fullWidth
          className={classes.noLabel}
          InputProps={{
            startAdornment: (
              <>
                <span className={classes.label}>
                  {translate("ra.pages.articles.fields.url") + " *"}
                </span>
                <span className={classes.orangesortUrl}>
                  https://orangesoft.co/blog/
                </span>
              </>
            )
          }}
        />
      )}
      <SelectArrayInput
        fullWidth
        label={`${translate("ra.pages.articles.fields.categories")} ${
          req ? "*" : ""
        }`}
        source="categories"
        className={classes.labelCategories}
        choices={categoriesChoices(translate)}
      />
      <ReferenceInput
        label={`${translate("ra.pages.articles.fields.author")} ${
          req ? "*" : ""
        }`}
        source="author"
        reference="authors"
        className={classnames(classes.author)}
        InputProps={{
          placeholder: "Type author name"
        }}
      >
        <AutocompleteInput optionText="fullName" />
      </ReferenceInput>
      <Editor
        source="text"
        label={`${translate("ra.pages.articles.fields.article_text")} ${
          req ? "*" : ""
        }`}
        change={change}
      />
      <OutlinedTextInput
        source="seoTitle"
        label={`${translate("ra.pages.articles.fields.seo_title")} ${
          req ? "*" : ""
        }`}
        change={change}
      />
      <OutlinedTextInput
        source="description"
        label={`${translate("ra.pages.articles.fields.description")} ${
          req ? "*" : ""
        }`}
        change={change}
      />
      <FAQ change={change} source="isShowFAQ" values={values} />

      {props.record?.id && (
        <VersionHistory />
      )}

      {props.record && props.record.id && (
        <>
          <DateTimeInput
            className={classes.date}
            source="date"
            label={translate("ra.pages.articles.fields.date")}
          />
          <DateTimeInput
            className={classes.lastUpdated}
            source="lastUpdated"
            label={translate("ra.pages.articles.fields.lastUpdated")}
          />
        </>
      )}
      <SelectInput
        className={classes.status}
        defaultValue="published"
        source="status"
        label={translate("ra.pages.articles.fields.status")}
        choices={choices(translate)}
      />
      {props.record && props.record.id && (
        <ReadTime classes={classes} translate={translate} />
      )}

      <Card className={classes.addMediaCard}>
        <CardContent>
          <ImagePicker
            source="preview"
            reduxFormName="preview"
            buttonLabel={translate("ra.pages.articles.actions.add_preview_picture")}
            required={req}
            record={props.record}
            change={change}
          />

          <ImagePicker
            source="heroImage"
            reduxFormName="heroImage"
            buttonLabel={translate("ra.pages.articles.actions.add_hero_picture")}
            required={req}
            record={props.record}
            change={change}
          />
        </CardContent>
      </Card>
    </SimpleForm>
  );
};
export default Form;
