import getUrl from "../common/getUrl";

const AuthQueryProvider = function(prefix) {
  this.headers = new Headers({
    "Content-Type": "application/json"
  });

  this.execute = async (url, body) => {
    try {
      let data = await fetch(prefix + url, {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify(body)
      });

      return await data.json();
    } catch (exep) {
      throw new Error(JSON.stringify(exep));
    }
  };
};

export default new AuthQueryProvider(`${getUrl()}/auth`);
