import {Button, Dialog, IconButton, withStyles} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {REDUX_FORM_NAME} from "react-admin";
import {Add, Close} from "@material-ui/icons";
import MediaListPreview from "./MediaListPreview";
import CustomImageInput from "./CustomImageInput";

const styles = theme => ({
  addMediaButton: {
    width: '100%'
  },
  addIcon: {
    fontSize: 20,
    marginRight: 6
  },
  closeButton: {
    position: "absolute",
    right: 2,
    top: 2,
    color: theme.palette.grey[500],
    zIndex: 100
  },
});

const ImagePicker = props => {
  const { change, classes, required, reduxFormName, buttonLabel, source } = props;

  const [isOpen, setOpenState] = useState(false);

  useEffect(() => {
    if (props.record[source] && props.record[source]._id) {
      change(REDUX_FORM_NAME, `${reduxFormName}Feature`, props.record[source]);
      change(REDUX_FORM_NAME, reduxFormName, props.record[source]._id);
    }
  }, [change, props.record, source, reduxFormName]);

  const toggleOpen = () => setOpenState(!isOpen);

  const handleChoose = photo => {
    change(REDUX_FORM_NAME, `${reduxFormName}Feature`, photo);
    change(REDUX_FORM_NAME, reduxFormName, photo.id);
    setOpenState(false);
  };

  const label = `${buttonLabel} ${required ? "*" : ""}`;

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={toggleOpen}
        className={classes.addMediaButton}
      >
        <Add className={classes.addIcon} />
        { label }
      </Button>

      {isOpen && (
        <Dialog
          maxWidth="sm"
          fullWidth
          open={true}
          onClose={toggleOpen}
          aria-labelledby="form-dialog-title"
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={toggleOpen}
          >
            <Close />
          </IconButton>
          <MediaListPreview handleChoose={handleChoose} />
        </Dialog>
      )}

      <CustomImageInput
        reduxFormName={reduxFormName}
      />
    </>
  );
};

export default withStyles(styles)(ImagePicker);
