import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  showNotification
} from "react-admin";
import { withStyles } from "@material-ui/core";
import DeleteButton from "../../common/DeleteButton";
import { CustomDateTimeField } from "../../common/CustomDateField";
import { styles } from "../../common/listStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Checkbox from "@material-ui/core/Checkbox";
import httpClient from "../../providers/httpClient";
import withTranslate from "ra-core/esm/i18n/translate";
import {connect} from "react-redux";

const Actions = ({ basePath, classes, translate }) => (
  <Toolbar className={classes.toolbar}>
    <CreateButton
      basePath={basePath}
      label={translate("ra.pages.infographics.actions.add")}
    />
  </Toolbar>
);

const DisplayOnSite = ({ record, handleChange }) => {
  return (
    <Checkbox
      checked={record.status === "published"}
      onClick={e => handleChange(e, record.id)}
      color="primary"
    />
  );
};

const CustomCopiableTextField = connect(
  null,
  { showNotification }
)(withTranslate(({record, source, translate, showNotification}) => {
  const value = record[source];

  const handleClick = e => {
    e.stopPropagation();

    const textArea = document.createElement('textarea');
    textArea.value = e.currentTarget.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    showNotification(`${translate("ra.notification.copied")}: ${value}`);
  };

  return (
    <div>
      <span
        title={value}
        onClick={handleClick}
      >
        {value}
      </span>
    </div>
  );
}));

const CustomDatagrid = connect(null, { showNotification })(({ classes, translate, ...props }) => {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data, setData]);

  const handleChange = async (e, id) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      const { json: { status } } = await httpClient(`/admin/infographics/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          status: data[id].status === "published" ? "draft" : "published"
        })
      });

      const newData = { ...data };
      newData[id].status = status;
      setData(newData);
    } catch (e) {
      props.showNotification(translate("ra.notification.required_fields"), 'warning');
    }
  };

  return (
    <Datagrid
      rowClick="edit"
      className={classes.tableWrapper}
      {...props}
      data={data}
    >
      <DisplayOnSite
        label=""
        source="status"
        sortable={false}
        handleChange={handleChange}
      />
      <CustomCopiableTextField
        source="id"
        label={translate("ra.pages.infographics.fields.id")}
      />
      <TextField
        source="title"
        sortable={false}
        label={translate("ra.pages.infographics.fields.title")}
      />
      <CustomDateTimeField
        label={translate("ra.pages.infographics.fields.date")}
        source="date"
        sortable={false}
        classes={classes}
      />
      <DeleteButton />
    </Datagrid>
  );
});

const InfographicList = ({ classes, translate, ...props }) => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      actions={
        <Actions classes={classes} translate={translate} />
      }
      className={classes.list}
      title={translate("ra.menu.infographics")}
      perPage={25}
    >
      <CustomDatagrid classes={classes} translate={translate} />
    </List>
  );
};

export default withStyles(styles)(withTranslate(InfographicList));
