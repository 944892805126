export default function getModalData(callback, params) {
  const container = document.createElement("div");
  container.setAttribute("id", "option-modal-container");
  container.innerHTML = modalCodeString;
  document.querySelector("body").appendChild(container);
  const bannerBackgroundElem = document.getElementById("bannerBackgroundColor");
  const buttonBackgroundElem = document.getElementById(
    "bannerButtonBackgroundColor"
  );
  const buttonTextColorElem = document.getElementById("bannerButtonTextColor");
  const popupTitleTextElem = document.getElementById("bannerPopUpTitle");
  if (params) {
    bannerBackgroundElem.value = params[0];
    buttonBackgroundElem.value = params[1];
    buttonTextColorElem.value = params[2];
    popupTitleTextElem.value = params[3];
  }
  document.getElementById(
    "editor-banner-options-modal-submit"
  ).onclick = () => {
    const result = validateInputs([
      bannerBackgroundElem,
      buttonBackgroundElem,
      buttonTextColorElem,
      popupTitleTextElem,
    ]);
    if (result) {
      callback(result);
      container.remove();
    }
  };
}

const validateInputs = (inputs) => {
  const resultArray = [];
  for (let element of inputs) {
    if (!element.value) {
      element.classList.add("error");
      element.oninput = (e) => {
        e.target.classList.remove("error");
        e.target.oninput = undefined;
      };
      return false;
    } else {
      resultArray.push(element.value);
    }
  }
  return resultArray;
};

const modalCodeString = `<div class='banner-modal-background'>
<style>
    .banner-modal-background{
        top:0px;
        left:0px;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(200, 200, 200, 0.5);
        z-index:9999;

    }
    .banner-modal-wrapper {
      
      position: absolute;
      top: 50%;
      left: 50%;
      height: 190px;
      width: 700px;
      margin: -125px 0 0px -350px;
      padding: 20px 24px;
      box-shadow: 0 1px 3px rgba(219, 55, 0, 0.95),
        0 1px 2px rgba(219, 55, 0, 1);
      border-radius: 5px;
      background-color: #fff;
    }
    .banner-modal-colors-part-wrapper{
        justify-content: space-between;
        display: flex;
    }
    .banner-modal-line {
      display: flex;
      border-bottom: 1px solid #000;
      box-sizing: border-box;
      margin: 16px 0px 8px 0px;
      height: 32px;
      align-items: center;
      padding-bottom: 1px;
    }
    .banner-modal-line:hover {
      box-shadow: 0px 1px black;
    }
    .banner-modal-line-text {
      white-space: nowrap;
      font-size: 16px;
      padding: 6px 10px 7px 0px;
    }
    .banner-modal-input-color {
      border: none;
      width: 55px;
      outline: none;
      height: 100%;
      /* background-color: blue; */
    }
    .banner-modal-input-color:active {
      border: none;
      outline: none;
    }
    .banner-modal-popup-input{
        outline: none;
        border: none;
        width:100%;
        height: 100%;
    }
    .banner-modal-popup-input:active{
        outline: none;
        border: none;

    }
    .error{
        border-bottom: 2px solid red;
    }
    .banner-modal-button-wrapper{
        display: flex;
        justify-content: center;
    }
    .banner-modal-submit-button {
      margin-top: 16px;
      width: 90px;
      height: 40px;
      color: #fff;
      background-color: #db3700;
      border-radius: 5px;
      outline: none;
      border: none;
    }
    .banner-modal-submit-button:hover{
filter: brightness(90%);
    }
  </style>
<section id="bannerModal" class="banner-modal-wrapper">
<div class="banner-modal-colors-part-wrapper">
<div class="banner-modal-line">
<span class="banner-modal-line-text"> background </span>
<input
  type="text"
  id="bannerBackgroundColor"
  class="banner-modal-input-color"
  placeholder="#000000"
  maxlength="7"
/>
</div>
<div class="banner-modal-line">
<span class="banner-modal-line-text">button background </span>
<input
  type="text"
  id="bannerButtonBackgroundColor"
  class="banner-modal-input-color"
  placeholder="#000000"
  maxlength="7"
/>
</div>
<div class="banner-modal-line">
<span class="banner-modal-line-text"> button text color</span>
<input
  type="text"
  id="bannerButtonTextColor"
  class="banner-modal-input-color"
  placeholder="#000000"
  maxlength="7"
/>
</div>
</div>
<div class="banner-modal-line">
<span class="banner-modal-line-text"> popup title</span>
<input
  type="text"
  id="bannerPopUpTitle"
  class="banner-modal-popup-input"
  placeholder="paste popup title"
  maxlength="100"
/>
</div>
<div class="banner-modal-button-wrapper">
<button class="banner-modal-submit-button" id='editor-banner-options-modal-submit'>Submit</button>
<div>
</section>
<div>`;
