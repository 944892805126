import React, { useState, useEffect } from "react";
import {
  SimpleForm,
  TextInput,
  SelectInput,
} from "react-admin";
import { DateTimeInput } from "../../common/DateInput";
import { Editor } from "../../common/Editor";
import {Card, CardContent} from "@material-ui/core";
import { Label } from "../../common/Label";
import { OutlinedTextInput } from "../../common/OutlinedTextInput";
import { validateInfographic } from "./validateInfographic";
import CustomToolbar from "./CustomToolbar";
import { choices } from "../../common/statuses";
import ImagePicker from "../../common/CustomImagePicker";

const Form = (originProps) => {
  const { classes, change, translate, values, ...props } = originProps;

  const [req, setRequired] = useState(false);

  useEffect(() => {
    setRequired(values?.status === "published");
  }, [values, setRequired]);

  return (
    <SimpleForm
      {...props}
      toolbar={<CustomToolbar values={values} />}
      validate={validateInfographic(translate)}
    >
      <TextInput
        source="title"
        fullWidth
        className={classes.noLabel}
        placeholder={translate("ra.pages.infographics.placeholders.title")}
        InputProps={{
          startAdornment: (
            <Label val={translate("ra.pages.infographics.fields.title") + " *"} />
          )
        }}
      />
      {props.record && props.record.id && (
        <TextInput
          source="url"
          fullWidth
          className={classes.noLabel}
          InputProps={{
            startAdornment: (
              <>
                <span className={classes.label}>
                  {translate("ra.pages.infographics.fields.url") + " *"}
                </span>
                <span className={classes.orangesoftUrl}>
                  https://orangesoft.co/infographics/
                </span>
              </>
            )
          }}
        />
      )}
      <Editor
        source="text"
        label={`${translate("ra.pages.infographics.fields.text")} ${
          req ? "*" : ""
        }`}
        change={change}
        disableImageUpload={true}
      />
      <OutlinedTextInput
        source="seoTitle"
        label={`${translate("ra.pages.infographics.fields.seo_title")} ${
          req ? "*" : ""
        }`}
        change={change}
      />
      <OutlinedTextInput
        source="description"
        label={`${translate("ra.pages.infographics.fields.description")} ${
          req ? "*" : ""
        }`}
        change={change}
        maxLen={160}
      />
      {props.record && props.record.id && (
        <DateTimeInput
          className={classes.date}
          source="date"
          label={translate("ra.pages.infographics.fields.date")}
        />
      )}
      <SelectInput
        className={classes.status}
        defaultValue="published"
        source="status"
        label={translate("ra.pages.infographics.fields.status")}
        choices={choices(translate)}
      />

      <Card className={classes.addMediaCard}>
        <CardContent>
          <ImagePicker
            source="preview"
            reduxFormName="preview"
            buttonLabel={translate("ra.pages.infographics.actions.add_preview_picture")}
            required={req}
            record={props.record}
            change={change}
          />

          <ImagePicker
            source="file"
            reduxFormName="file"
            buttonLabel={translate("ra.pages.infographics.actions.add_graphic")}
            required={req}
            record={props.record}
            change={change}
          />
        </CardContent>
      </Card>
    </SimpleForm>
  );
};

export default Form;
