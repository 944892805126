import React, { useEffect, useState } from "react";
import Content from "../articlePreview/Content/Content";
import diff from 'node-htmldiff';

import "./article.scss";


const Article = props => {
  const [state, setState] = useState(null);

  useEffect(async () => {
    const current = localStorage.getItem('currentVersion');
    const previous = localStorage.getItem('previousVersion');

    const dif = diff(previous, current);

    setState(dif);
  }, []);

  return state && (
    <main className="main-blog-container">
      <Content content={state} />
    </main>
  );
};

export default Article;
