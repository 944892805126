import React, { useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
  Toolbar,
  SaveButton,
  required,
  maxLength
} from "react-admin";
import { withStyles } from "@material-ui/core";
import { Label } from "../../common/Label";
import Video from "../../common/Video";
import withTranslate from "ra-core/esm/i18n/translate";

const styles = theme => ({
  noLabel: {
    "& label": {
      display: "none"
    }
  },
  toolbar: {
    backgroundColor: "inherit",
    paddingTop: 24,
    paddingBottom: 24,
    [theme.breakpoints.down("xs")]: {
      padding: 16,
      backgroundColor: "#f5f5f5"
    }
  },
  addMedia: {
    marginTop: 40,
    width: 276,
    "& > div": {
      padding: 0,
      "& > span > div:first-child": {
        height: 36,
        padding: 0,
        border: "1px solid rgba(232, 85, 31, 0.5)",
        borderRadius: 4,
        boxSizing: "border-box",
        backgroundColor: "transparent",
        "& p": {
          margin: 0,
          padding: "8px 0",
          color: theme.palette.primary.main
        }
      },
      "& > span > div:first-child:hover": {
        backgroundColor: "rgba(232, 85, 31, 0.08)"
      }
    },
    "& label": {
      display: "none"
    },
    "& svg": {
      fill: theme.palette.primary.main
    }
  },
  image: {
    "& img": {
      width: 276,
      margin: "10px 0",
      borderRadius: 4,
      maxHeight: 350,
      objectFit: "scale-down",
      cursor: "pointer"
    }
  },
  video: {
    margin: "10px 0",
    borderRadius: 4,
    maxHeight: 350,
    objectFit: "scale-down",
    cursor: "pointer"
  },
  saveButton: {
    textTransform: "none",
    width: 85,
    "& span": {
      "& div": {
        display: "none !important"
      }
    },
    "& svg": {
      display: "none"
    }
  }
});

const CustomToolbar = React.memo(({ classes, ...props }) => {
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton redirect="list" className={classes.saveButton} />
    </Toolbar>
  );
});

const SubImageField = React.memo(({ record, classes }) => {
  const [childRecord, setChildRecord] = useState({});
  useEffect(() => {
    if (typeof record === "object") {
      setChildRecord({
        src: record.rawFile.preview
      });
    } else
      setChildRecord({
        src: record
      });
  }, [record]);

  return record.rawFile.type === "video/mp4" ? (
    <Video src={childRecord.src} className={classes.video} />
  ) : (
    <ImageField source="src" record={childRecord} className={classes.image} />
  );
});
const MediaCreate = ({ classes, translate, ...props }) => {
  return (
    <Create {...props} title={translate("ra.pages.media.create_media_heading")}>
      <SimpleForm toolbar={<CustomToolbar classes={classes} />}>
        <TextInput
          source="name"
          validate={[required(), maxLength(50)]}
          fullWidth
          className={classes.noLabel}
          placeholder={translate("ra.pages.media.placeholders.name")}
          InputProps={{
            startAdornment: (
              <Label val={translate("ra.pages.media.fields.name") + " *"} />
            )
          }}
        />
        <TextInput
          source="altName"
          validate={[required(), maxLength(100)]}
          fullWidth
          className={classes.noLabel}
          placeholder={translate("ra.pages.media.placeholders.alt_name")}
          InputProps={{
            startAdornment: (
              <Label val={translate("ra.pages.media.fields.alt_name") + " *"} />
            )
          }}
        />
        <ImageInput
          accept="image/*, video/mp4"
          className={classes.addMedia}
          source="photo"
          validate={[required()]}
          placeholder={
            <p>{translate("ra.pages.media.actions.pick_media") + " *"}</p>
          }
        >
          <SubImageField classes={classes} />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default withStyles(styles)(withTranslate(MediaCreate));
