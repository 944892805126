import React, { useState, useEffect } from "react";
import { SaveButton, Toolbar } from "react-admin";
import { validatePreview } from "./validatePreview";
import DeleteButton from "../../common/DeleteButton";
import classnames from "classnames";
import { Button, withStyles } from "@material-ui/core";
import { withTranslate } from "ra-core";

const styles = theme => ({
  previewLink: {
    textDecoration: "none",
    "&[disabled]": {
      pointerEvents: "none"
    }
  },
  buttonText: {
    textTransform: "none",
    "&:disabled": {
      color: theme.palette.primary.main,
      opacity: 0.5
    }
  },
  previewButton: {
    marginRight: 10
  },
  saveButton: {
    marginRight: 10,
    width: 85,
    "& span": {
      "& div": {
        display: "none"
      }
    },
    "& svg": {
      display: "none"
    }
  }
});

const CustomToolbar = ({ classes, values, translate, ...props }) => {
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (values)
      if (Object.keys(values).length) {
        if (Object.keys(validatePreview(translate)(values)).length) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      }
  }, [values]);
  const setItem = () => {
    localStorage.setItem("work-preview", JSON.stringify(values));
  };
  return (
    <Toolbar {...props}>
      <SaveButton
        redirect="list"
        className={classnames(classes.buttonText, classes.saveButton)}
      />
      {props.record && props.record.id && <DeleteButton />}
    </Toolbar>
  );
};
export default withStyles(styles)(withTranslate(CustomToolbar));
