// import BaseDateFnsUtils from "@date-io/date-fns";
import {
  DateTimePicker as BaseDateTimePicker,
  MuiPickersUtilsProvider
} from "material-ui-pickers";
import { addField, FieldTitle } from "ra-core";
import React, { Component } from "react";
import {
  ChevronLeft,
  ChevronRight,
  DateRange,
  Schedule
} from "@material-ui/icons";
import MomentUtils from "@date-io/moment";
import "moment/locale/ru";
import "moment/locale/en-ca";
import { lang } from "../providers/i18nProvider";
class DateFnsUtils extends MomentUtils {
  getStartOfMonth(value) {
    return this.startOfMonth(value);
  }
}

class BasePickerComponent extends Component {
  onChange(date) {
    this.props.input.onChange(date);
    this.props.input.onBlur();
  }
  render() {
    const {
      input,
      options,
      label,
      source,
      resource,
      isRequired,
      className,
      meta
    } = this.props;

    const { touched, error } = meta;

    return (
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={lang === "by" ? "ru" : "en-ca"}
      >
        <BaseDateTimePicker
          dateRangeIcon={<DateRange />}
          timeIcon={<Schedule />}
          leftArrowIcon={<ChevronLeft />}
          rightArrowIcon={<ChevronRight />}
          {...options}
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          margin="normal"
          error={!!(touched && error)}
          helperText={touched && error}
          ref={node => {
            this.picker = node;
          }}
          className={className}
          value={input.value ? input.value : null}
          onChange={date => this.onChange(date)}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

BasePickerComponent.defaultProps = {
  meta: { touched: false, error: false }
};

const DateTimePicker = props => <BasePickerComponent {...props} />;

export const DateTimeInput = React.memo(addField(DateTimePicker));
