import React from "react";
import { DeleteButton as DB } from "react-admin";
import { withStyles } from "@material-ui/core";
const styles = theme => ({
  delete: {
    border: "1px solid rgba(232, 85, 31, 0.5)",
    width: 42,
    minWidth: 42,
    borderRadius: 4,
    height: 36,
    color: theme.palette.primary.main,
    "& > span span": {
      display: "none"
    }
  }
});

const DeleteButton = ({ classes, ...props }) => (
  <DB
    {...props}
    undoable={false}
    className={classes.delete}
    icon={
      <svg
        width="16"
        height="20"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.4213 21.4567L18.1398 5.78716H19.3894C19.8164 5.78716 20.16 5.40997 20.16 4.96812C20.16 4.52627 19.8164 4.15986 19.3894 4.15986H14.6306V2.54333C14.6306 0.991468 13.6205 0 12.0169 0H8.12231C6.51868 0 5.51901 0.991468 5.51901 2.54333V4.15986H0.770578C0.35405 4.15986 0 4.53705 0 4.96812C0 5.40997 0.35405 5.78716 0.770578 5.78716H2.03058L2.74909 21.4674C2.82198 23.0085 3.80083 24 5.31074 24H14.8597C16.3488 24 17.3484 22.9978 17.4213 21.4567ZM7.18512 2.6511C7.18512 2.03682 7.61207 1.60575 8.24727 1.60575H11.9023C12.5375 1.60575 12.9749 2.03682 12.9749 2.6511V4.15986H7.18512V2.6511ZM5.45653 22.3727C4.86298 22.3727 4.41521 21.9093 4.38397 21.2735L3.65504 5.78716H16.4737L15.7864 21.2735C15.7552 21.9201 15.3179 22.3727 14.6931 22.3727H5.45653ZM13.1727 20.4868C13.5164 20.4868 13.7663 20.1958 13.7767 19.7863L14.0891 8.48137C14.0995 8.08262 13.8392 7.78087 13.4851 7.78087C13.1623 7.78087 12.8916 8.0934 12.8812 8.48137L12.5688 19.7755C12.5583 20.1742 12.8187 20.4868 13.1727 20.4868ZM6.99769 20.4868C7.35174 20.4868 7.61207 20.1742 7.60165 19.7755L7.27884 8.48137C7.26843 8.0934 6.99769 7.78087 6.67488 7.78087C6.32083 7.78087 6.0605 8.08262 6.07091 8.48137L6.38331 19.7863C6.39372 20.1958 6.64364 20.4868 6.99769 20.4868ZM10.08 20.4868C10.4236 20.4868 10.7152 20.1742 10.7152 19.7863V8.48137C10.7152 8.0934 10.4236 7.78087 10.08 7.78087C9.74678 7.78087 9.45521 8.0934 9.45521 8.48137V19.7863C9.45521 20.1742 9.74678 20.4868 10.08 20.4868Z"
          fill="#F84600"
        />
      </svg>
    }
  />
);

export default React.memo(withStyles(styles)(DeleteButton));
