import React from "react";

import "./author.scss";

const Author = ({ author }) => {
  return (
    <section className="article-author-container">
      <figure className="figure">
        <img
          src={author.photo}
          alt={`${author.firstName} ${author.lastName}`}
          className="author-avatar"
          loading="lazy"
        />
      </figure>
      <div className="author-info">
        <p className="full-name">{`${author.firstName} ${author.lastName}`}</p>
        <p className="job-titile">
          {author.jobTitle}
        </p>
      </div>
    </section>
  );
};

export default Author;
