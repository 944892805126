import React from "react";
import { Edit } from "react-admin";
import { withStyles } from "@material-ui/core";
import Form from "./Form";
import { change } from "redux-form";
import { connect } from "react-redux";
import { withTranslate } from "ra-core";

const styles = theme => ({
  form: {
    marginRight: 280,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0
    }
  }
});

const Title = ({ record, translate }) => {
  return (
    <>
      {`${translate("ra.pages.authors.edit_author_heading")} ${
        record.fullName
      }`}
    </>
  );
};

const AuthorsEdit = ({ classes, change, translate, ...props }) => {
  return (
    <Edit
      {...props}
      className={classes.form}
      title={<Title translate={translate} />}
    >
      <Form change={change} />
    </Edit>
  );
};

export default connect(
  null,
  { change }
)(withStyles(styles)(withTranslate(AuthorsEdit)));
