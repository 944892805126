import jwt from "jsonwebtoken";
import AuthQueryProvider from "../providers/authQueryProvider";

export const addBearer = async options => {
  let token = localStorage.getItem("token");

  if (token) {
    const refreshToken = localStorage.getItem("refreshToken");

    const { exp } = jwt.decode(token);

    if (Date.now() >= exp * 1000) {
      try {
        let dataRefresh = await AuthQueryProvider.execute("/refresh", {
          refreshToken
        });
        localStorage.setItem("token", dataRefresh.token);
        token = dataRefresh.token;
      } catch (error) {
        localStorage.removeItem("login");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
      }
    }
  }

  if (!options.headers) {
    options.headers = new Headers({
      Authorization: "Bearer " + token
    });
  } else {
    options.headers.append("Authorization", "Bearer " + token);
  }
  return options;
};
