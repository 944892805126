import React from "react";
import { Login } from "react-admin";

const CustomLogin = props => (
  <Login
    {...props}
    backgroundImage=""
  />
);

export default React.memo(CustomLogin);
