export default {
  ra: {
    action: {
      add_filter: "Add filter",
      add: "Add",
      back: "Go Back",
      bulk_actions: "1 item selected |||| %{smart_count} items selected",
      cancel: "Cancel",
      clear_input_value: "Clear value",
      clone: "Clone",
      confirm: "Confirm",
      create: "Create",
      delete: "Delete",
      edit: "Edit",
      export: "Export",
      list: "List",
      refresh: "Refresh",
      remove_filter: "Remove this filter",
      remove: "Remove",
      save: "Save",
      search: "Search",
      show: "Show",
      sort: "Sort",
      undo: "Undo",
      expand: "Expand",
      close: "Close",
      open_menu: "Open menu",
      close_menu: "Close menu"
    },
    boolean: {
      true: "Yes",
      false: "No",
      null: ""
    },
    page: {
      create: "Create %{name}",
      dashboard: "Dashboard",
      edit: "%{name} #%{id}",
      error: "Something went wrong",
      list: "%{name}",
      loading: "Loading",
      not_found: "Not Found",
      show: "%{name} #%{id}",
      empty: "No %{name} yet.",
      invite: "Do you want to add one?"
    },
    input: {
      file: {
        upload_several: "Drop some files to upload, or click to select one.",
        upload_single: "Drop a file to upload, or click to select it."
      },
      image: {
        upload_several: "Drop some pictures to upload, or click to select one.",
        upload_single: "Drop a picture to upload, or click to select it."
      },
      references: {
        all_missing: "Unable to find references data.",
        many_missing:
          "At least one of the associated references no longer appears to be available.",
        single_missing:
          "Associated reference no longer appears to be available."
      },
      password: {
        toggle_visible: "Hide password",
        toggle_hidden: "Show password"
      }
    },
    message: {
      about: "About",
      are_you_sure: "Are you sure?",
      bulk_delete_content:
        "Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?",
      bulk_delete_title: "Delete %{name} |||| Delete %{smart_count} %{name}",
      delete_content: "Are you sure you want to delete this item?",
      delete_title: "Delete %{name} #%{id}",
      details: "Details",
      error: "A client error occurred and your request couldn't be completed.",
      invalid_form: "The form is not valid. Please check for errors",
      loading: "The page is loading, just a moment please",
      no: "No",
      not_found: "Either you typed a wrong URL, or you followed a bad link.",
      yes: "Yes",
      unsaved_changes:
        "Some of your changes weren't saved. Are you sure you want to ignore them?"
    },
    navigation: {
      no_results: "No results found",
      no_more_results:
        "The page number %{page} is out of boundaries. Try the previous page.",
      page_out_of_boundaries: "Page number %{page} out of boundaries",
      page_out_from_end: "Cannot go after last page",
      page_out_from_begin: "Cannot go before page 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} of %{total}",
      page_rows_per_page: "Rows per page:",
      next: "Next",
      prev: "Prev"
    },
    auth: {
      auth_check_error: "Please login to continue",
      user_menu: "Profile",
      username: "Username",
      password: "Password",
      sign_in: "Sign in",
      sign_in_error: "Authentication failed, please retry",
      logout: "Logout"
    },
    notification: {
      updated: "Element updated |||| %{smart_count} elements updated",
      created: "Element created",
      deleted: "Element deleted |||| %{smart_count} elements deleted",
      bad_item: "Incorrect element",
      item_doesnt_exist: "Element does not exist",
      http_error: "Server communication error",
      data_provider_error: "dataProvider error. Check the console for details.",
      i18n_error: "Cannot load the translations for the specified language",
      canceled: "Action cancelled",
      logged_out: "Your session has ended, please reconnect.",
      wrong: "Something went wrong",
      saved: "Saved",
      copied: "Copied",
      required_fields: "Fill all required fields"
    },
    validation: {
      required: "Required",
      minLength: "Must be %{min} characters at least",
      maxLength: "Must be %{max} characters or less",
      minValue: "Must be at least %{min}",
      maxValue: "Must be %{max} or less",
      number: "Must be a number",
      email: "Must be a valid email",
      oneOf: "Must be one of: %{options}",
      regex: "Must match a specific format (regexp): %{pattern}",
      equal: "New password and confirmation password do not match",
      equal_new_and_old: "New password could not be same as old password",
      url_not_valid: "URL not valid",
      only: "Please use only numbers, lower case latin letters or symbols",
      only_latin: "Please use only latin letters",
      a_s_url_not_valid: "App Store URL not valid",
      p_m_rl_not_valid: "Play Market URL not valid",
      color_not_valid: "Hex color not valid",
      priority_not_valid: "Value should be greater than 0"
    },
    menu: {
      articles: "Articles",
      works: "Works",
      media: "Media",
      emails: "Emails",
      authors: "Authors",
      files: "Files",
      seo: "SEO",
      robots: "Robots",
      settings: "Settings",
      infographics: 'Infographics'
    },
    pages: {
      articles: {
        edit_article_heading: "Edit Article",
        create_article_heading: "Create Article",
        actions: {
          add: "Add article",
          preview: "Preview",
          add_question: "Add question",
          add_preview_picture: "Add preview picture",
          add_hero_picture: "Add article picture",
          rollback: "Rollback to Selected Version",
          compare: "Compare"
        },
        fields: {
          id: 'ID',
          title: "Title",
          date: "Date",
          lastUpdated: "Last update",
          categories: "Categories",
          author: "Author",
          url: "URL",
          article_text: "Article text",
          seo_title: "SEO title",
          description: "Description",
          add_faq: "Add FAQ",
          question: "Question:",
          answer: "Answer:",
          status: "Status",
          read_time: "Read time (min):",
          version_history: "Version History"
        },
        placeholders: {
          title: "Enter title here"
        },
        categories: {
          client_guides: "Client Guides",
          tech: "Tech",
          design: "Design",
          startups: "Startups",
          orangesoft: "Orangesoft"
        },
        rollback_dialog: {
          title: "Are you sure?",
          confirm: "Yes",
          cancel: "Cancel"
        },
        notifications: {
          compare_error: "Something went wrong. Unable to compare.",
        }
      },
      infographics: {
        create_heading: 'Create infographic',
        edit_heading: 'Edit infographic',
        actions: {
          add_preview_picture: 'Add preview picture',
          add_graphic: 'Add infographic',
          add: 'Create infographic'
        },
        placeholders: {
          title: "Enter title here"
        },
        fields: {
          id: 'ID',
          title: "Title",
          date: "Date",
          url: "URL",
          text: "Text",
          seo_title: "SEO title",
          description: "Description",
          status: "Status",
        }
      },
      works: {
        edit_work_heading: "Edit Work-case",
        create_work_heading: "Create Work-case",
        actions: {
          add: "Add work",
          preview: "Preview",
          add_preview_picture: "Add preview picture",
          add_small_preview_picture: "Add small preview picture",
          add_large_preview_picture: "Add large preview picture",
          add_case_picture: "Add case picture"
        },
        fields: {
          case_study: "Case study",
          title: "Title",
          categories: "Categories",
          services: "Services",
          priority: "Priority",
          date: "Date",
          status: "Status",
          type: "Type",
          url: "URL",
          industry: "Industry",
          duration: "Duration",
          teamSize: "Team size",
          tech_stack: "Tech stack",
          work_case_text: "Work-case text",
          app_store_url: "App Store URL",
          play_market_url: "Play Market URL",
          seo_title: "SEO title",
          description: "Description",
          preview_size: "Preview size"
        },
        placeholders: {
          title: "Enter title here",
          industry: "Enter industry name here",
          duration: "Enter duration here",
          team: "Describe team here",
          app_store_url: "Enter URL here",
          play_market_url: "Enter URL here",
          backgroundColor: "Background color",
          accentColor: "Accent color"
        }
      },
      media: {
        edit_media_heading: "Edit",
        create_media_heading: "Create Media",
        in_articles: "In articles: ",
        in_work_cases: "In work-cases: ",
        preview_f_a: "Preview for articles: ",
        preview_f_cs: "Preview for case-study: ",
        as_hero_image: "Hero image for: ",
        infographic: "Infographic for: ",
        infographic_preview: "Infographic preview for: ",
        list: "List",
        add_new: "Add new",
        actions: {
          add: "Add media",
          pick_media: "Pick media"
        },
        fields: {
          name: "Name",
          alt_name: "Alt Name",
          url: "URL",
          date: "Date",
          file_type: "File type",
          file_size: "File size",
          file_resolution: "File resolution"
        },
        placeholders: {
          name: "Enter name here",
          alt_name: "Enter alt name here"
        }
      },
      emails: {
        fields: {
          email: "Email",
          date: "Date"
        }
      },
      authors: {
        edit_author_heading: "Edit Author",
        create_author_heading: "Create Author",
        actions: {
          add: "Add author",
          pick_avatar: "Pick avatar"
        },
        fields: {
          full_name: "Full name",
          num_posts: "Num posts",
          first_name: "First Name",
          last_name: "Last Name",
          linkedin_url: "Linkedin URL",
          website_url: "Website URL",
          bio: "Bio",
          jobTitle: "Job title"
        },
        placeholders: {
          first_name: "Enter first name here",
          last_name: "Enter last name here",
          linkedin_url: "Enter URL here",
          website_url: "Enter URL here"
        }
      },
      files: {
        actions: {
          add: "Add file",
          upload: "Upload",
          uploaded: "Uploaded"
        },
        fields: {
          title: "Title",
          route: "Route",
          file: "File",
          date: "Date"
        }
      },
      seo: {
        actions: {
          add: "Add page"
        },
        fields: {
          route: "Route",
          title: "Title",
          description: "Description"
        }
      },
      settings: {
        fields: {
          login: "Login",
          old_password: "Old password",
          new_password: "New password",
          confirm_password: "Confirm password"
        },
        placeholders: {
          old_password: "Enter old password",
          new_password: "Enter new password",
          confirm_password: "Enter confirm password"
        }
      },
      common: {
        fields: {
          statuses: {
            draft: "Draft",
            published: "Published"
          },
          types: {
            na: "N/A",
            custom: "Custom",
            template: "Template"
          }
        }
      }
    }
  }
};
