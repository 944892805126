import React from "react";
import { SaveButton, Toolbar } from "react-admin";
import DeleteButton from "../../common/DeleteButton";
import classnames from "classnames";
import { withStyles } from "@material-ui/core";
import { withTranslate } from "ra-core";

const styles = theme => ({
  buttonText: {
    textTransform: "none",
    "&:disabled": {
      color: theme.palette.primary.main,
      opacity: 0.5
    }
  },
  saveButton: {
    marginRight: 10,
    marginLeft: -6,
    width: 85,
    "& span": {
      "& div": {
        display: "none"
      }
    },
    "& svg": {
      display: "none"
    }
  }
});

const CustomToolbar = ({
  classes,
  values,
  translate,
  ...props
}) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        redirect="list"
        className={classnames(classes.buttonText, classes.saveButton)}
      />
      {props.record && props.record.id && <DeleteButton />}
    </Toolbar>
  );
};
export default withStyles(styles)(withTranslate(CustomToolbar));
