import React from "react";

export const CustomDateTimeField = React.memo(({ record, source, classes }) => {
  return (
    <span className={classes.tdStyle}>
      {record[source] && new Date(record[source]).toLocaleString()}
    </span>
  );
});

export const CustomDateField = React.memo(({ record, source, classes }) => {
  return (
    <span className={classes.tdStyle}>
      {record[source] && new Date(record[source]).toLocaleDateString()}
    </span>
  );
});
