import React from "react";
import { withStyles, TextField } from "@material-ui/core";
import classnames from "classnames";
import { withTranslate } from "react-admin";
import { ROUTE_NAME_REGEXP } from './constants';

const styles = theme => ({
  wrapper: {
    marginBottom: 8,
    "& > div": {
      border: "2px solid transparent",
      borderRadius: 4,
      marginTop: 6,
      padding: "0 2px",
      "& textarea": {
        overflow: "overlay"
      }
    },
    "& > p": {
      margin: 0,
      fontSize: "0.75rem",
      marginTop: 8,
      minHeight: "1em",
      lineHeight: "1em",
      color: theme.palette.error.main
    },
    "&:focus-within": {
      "& > div": {
        background: "#ffffff",
        border: `2px solid ${theme.palette.primary.dark}`
      }
    },
    "&:hover": {
      "& > div": {
        background: "#ffffff",
        border: `2px solid rgba(0, 0, 0, 0.87)`
      }
    }
  },
  error: {
    "& > div": {
      border: `2px solid ${theme.palette.error.main}`
    },
    "&:hover": {
      "& > div": {
        border: `2px solid ${theme.palette.error.main}`
      }
    }
  }
});
class CustomField extends React.Component {
  state = {
    val: "",
    err: ""
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.val !== nextState.val) {
      return true;
    }
    return false;
  }
  componentDidMount() {
    const { ndata, record, source } = this.props;
    if (ndata[record.id]) {
      this.setState({
        val: ndata[record.id][source]
      });
    }
  }

  handleChange = e => {
    let v = e.target.value;

    const { source, record, setNdata, ndata, translate } = this.props;
    if (source === "routeName") {
      if (v.length > 120)
        this.setState({
          err: translate("ra.validation.maxLength", { max: 120 })
        });
      else if (v && !v.match(ROUTE_NAME_REGEXP))
        this.setState({
          err: translate("ra.validation.only")
        });
      else this.setState({ err: "" });
    } else if (source === "title") {
      if (v.length > 100)
        this.setState({
          err: translate("ra.validation.maxLength", { max: 160 })
        });
      else this.setState({ err: "" });
    }

    this.setState({ val: v });
    let obj = ndata[record.id];
    obj[source] = v;
    obj.touched = true;
    setNdata({ ...ndata, [record.id]: obj });
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        className={classnames(
          classes.wrapper,
          this.state.err ? classes.error : null
        )}
      >
        <div>
          <TextField
            fullWidth
            multiline
            onChange={this.handleChange}
            value={this.state.val}
            variant="outlined"
            InputProps={{ disableUnderline: true }}
          />
        </div>
        {this.state.err && <p>{this.state.err}</p>}
      </div>
    );
  }
}
export const Field = React.memo(withStyles(styles)(withTranslate(CustomField)));
