import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import {
  SimpleForm,
  showNotification,
  Toolbar,
  SaveButton,
  REDUX_FORM_NAME
} from "react-admin";
import httpClient from "../../providers/httpClient";
import { connect } from "react-redux";
import { getFormValues, change } from "redux-form";
import { withStyles } from "@material-ui/core";
import { OutlinedTextInput } from "../../common/OutlinedTextInput";
import { withTranslate } from "ra-core";

const styles = theme => ({
  toolbar: {
    backgroundColor: "inherit",
    paddingTop: 24,
    paddingBottom: 24,
    [theme.breakpoints.down("xs")]: {
      padding: 16,
      backgroundColor: "#f5f5f5"
    }
  },
  form: {
    maxWidth: 900
  },
  saveButton: {
    textTransform: "none",
    width: 85,
    "& span": {
      "& div": {
        display: "none"
      }
    },
    "& svg": {
      display: "none"
    }
  },
  robotsTitle: {
    fontWeight: "500",
    fontSize: 22,
    position: "absolute",
    top: 11,
    zIndex: 10000,
    left: 72,
    color: "#fff",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    [theme.breakpoints.down("xs")]: {
      top: 15
    }
  }
});

const CustomToolbar = ({ classes, ...props }) => {
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton className={classes.saveButton} />
    </Toolbar>
  );
};

const Robots = props => {
  const { classes, change, translate } = props;

  const [record, setRecord] = useState({});
  useEffect(() => {
    httpClient("/seo/robots", {
      method: "GET"
    })
      .then(res => {
        setRecord(res.json);
      })
      .catch(err => {
        showNotification(translate("ra.notification.wrong"), "warning");
      });
  }, []);

  const handleSave = () => {
    const {
      values: { text },
      showNotification
    } = props;
    httpClient("/seo/robots", {
      method: "PUT",
      body: JSON.stringify({
        text
      })
    })
      .then(res => {
        showNotification(translate("ra.notification.saved"));
      })
      .catch(err => {
        showNotification(translate("ra.notification.wrong"), "warning");
      });
  };

  return (
    <Paper elevation={1} className={classes.form}>
      <span className={classes.robotsTitle}>{translate("ra.menu.robots")}</span>
      {!!Object.keys(record).length && (
        <SimpleForm
          toolbar={<CustomToolbar classes={classes} />}
          record={record}
          save={handleSave}
        >
          <OutlinedTextInput source="text" label="" change={change} />
        </SimpleForm>
      )}
    </Paper>
  );
};

export default connect(
  state => ({
    values: getFormValues(REDUX_FORM_NAME)(state)
  }),
  { showNotification, change }
)(withStyles(styles)(withTranslate(Robots)));
