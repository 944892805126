import red from "@material-ui/core/colors/red";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

const overrides = {
  MuiDialog: {
    paper: {
      marginLeft: 24,
      marginRight: 24,

      [breakpoints.down("xs")]: {
        width: "calc(100% - 48px)"
      }
    }
  },
  MuiDialogContent: {
    root: {
      paddingBottom: 0
    }
  },
  MuiDialogContentText: {
    root: {
      [breakpoints.down("xs")]: {
        minWidth: "200px!important"
      }
    }
  },
  MuiChip: {
    root: {
      height: "auto",
      lineHeight: "24px",
      paddingTop: 4,
      paddingBottom: 4
    },
    label: {
      whiteSpace: "initial"
    }
  },
  MuiTableCell: {
    root: {
      "&[colspan]": {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0
      }
    }
  }
};

const lightTheme = {
  palette: {
    primary: {
      light: "#ff7542",
      main: "#e8551f",
      dark: "#cf4a19"
    },
    secondary: {
      light: red.A100,
      main: red.A400,
      dark: red.A700
    },
  },
  overrides
};

export default lightTheme;
