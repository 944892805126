import React, { useState, useEffect } from "react";
import {
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
  Toolbar,
  SaveButton,
  required,
  maxLength,
} from "react-admin";
import { withStyles } from "@material-ui/core";
import { Label } from "../../common/Label";
import { OutlinedTextInput } from "../../common/OutlinedTextInput";
import withTranslate from "ra-core/esm/i18n/translate";

const urlPattern = new RegExp(
  "^(https?:\\/\\/)?" +
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
    "((\\d{1,3}\\.){3}\\d{1,3}))" +
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
    "(\\?[;&a-z\\d%_.~+=-]*)?" +
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

const styles = theme => ({
  toolbar: {
    backgroundColor: "inherit",
    paddingTop: 24,
    paddingBottom: 24,
    [theme.breakpoints.down("xs")]: {
      padding: 16,
      backgroundColor: "#f5f5f5"
    }
  },
  noLabel: {
    "& label": {
      display: "none"
    }
  },
  addIcon: {
    fontSize: 20,
    paddingRight: 8
  },
  addPhoto: {
    position: "absolute",
    top: 0,
    right: -280,
    width: 256,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      position: "static",
      marginTop: 16,
      marginBottom: 8
    },
    "& > div": {
      padding: 0,
      "& > span > div:first-child": {
        height: 36,
        padding: 0,
        border: "1px solid rgba(232, 85, 31, 0.5)",
        borderRadius: 4,
        boxSizing: "border-box",
        backgroundColor: "transparent",
        "& p": {
          margin: 0,
          padding: "8px 0",
          color: theme.palette.primary.main
        }
      },
      "& > span > div:first-child:hover": {
        backgroundColor: "rgba(232, 85, 31, 0.08)"
      }
    },
    "& label": {
      display: "none"
    },
    "& svg": {
      fill: theme.palette.primary.main
    }
  },
  image: {
    "& img": {
      width: 256,
      margin: "10px 0",
      borderRadius: 4,
      maxHeight: 350,
      objectFit: "scale-down",
      cursor: "pointer"
    }
  },
  labelBio: {
    padding: "0 20px 7px 0",
    color: "#000"
  },
  saveButton: {
    textTransform: "none",
    marginRight: 10,
    width: 85,
    "& span": {
      "& div": {
        display: "none"
      }
    },
    "& svg": {
      display: "none"
    }
  }
});

const CustomToolbar = ({ classes, ...props }) => {
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton redirect="list" className={classes.saveButton} />
    </Toolbar>
  );
};

const SubImageField = ({ record, classes }) => {
  const [childRecord, setChildRecord] = useState({});
  useEffect(() => {
    if (typeof record === "object") {
      setChildRecord({
        src: record.rawFile.preview
      });
    } else
      setChildRecord({
        src: record
      });
  }, [record]);
  return (
    <ImageField source="src" record={childRecord} className={classes.image} />
  );
};
const Form = ({ classes, change, translate, ...props }) => {
  const validURL = (
    message = translate("ra.validation.url_not_valid")
  ) => value =>
    !value || (value && value.match(urlPattern)) ? undefined : message;
  return (
    <SimpleForm toolbar={<CustomToolbar classes={classes} />} {...props}>
      <ImageInput
        accept="image/*"
        className={classes.addPhoto}
        source="photo"
        validate={[required()]}
        placeholder={
          <p>{translate("ra.pages.authors.actions.pick_avatar") + " *"}</p>
        }
      >
        <SubImageField classes={classes} />
      </ImageInput>
      <TextInput
        source="firstName"
        validate={[required(), maxLength(20)]}
        fullWidth
        className={classes.noLabel}
        placeholder={translate("ra.pages.authors.placeholders.first_name")}
        InputProps={{
          startAdornment: (
            <Label
              val={translate("ra.pages.authors.fields.first_name") + " *"}
            />
          )
        }}
      />
      <TextInput
        source="lastName"
        validate={[required(), maxLength(20)]}
        fullWidth
        className={classes.noLabel}
        placeholder={translate("ra.pages.authors.placeholders.last_name")}
        InputProps={{
          startAdornment: (
            <Label
              val={translate("ra.pages.authors.fields.last_name") + " *"}
            />
          )
        }}
      />
      <TextInput
        source="linkedinUrl"
        fullWidth
        className={classes.noLabel}
        validate={[validURL()]}
        placeholder={translate("ra.pages.authors.placeholders.linkedin_url")}
        InputProps={{
          startAdornment: (
            <Label val={translate("ra.pages.authors.fields.linkedin_url")} />
          )
        }}
      />
      <TextInput
        source="websiteUrl"
        fullWidth
        className={classes.noLabel}
        validate={[validURL()]}
        placeholder={translate("ra.pages.authors.placeholders.website_url")}
        InputProps={{
          startAdornment: (
            <Label val={translate("ra.pages.authors.fields.website_url")} />
          )
        }}
      />
      <OutlinedTextInput
        source="jobTitle"
        label={translate("ra.pages.authors.fields.jobTitle") + " *"}
        change={change}
        validate={[required(), maxLength(250)]}
      />
      <OutlinedTextInput
        source="biography"
        label={'Bio'}
        change={change}
        validate={[maxLength(300)]}
      />
    </SimpleForm>
  );
};
export default withStyles(styles)(withTranslate(Form));
