import {
  Typography,
  Button,
  MuiThemeProvider,
  Select,
  Dialog,
  DialogTitle,
  DialogActions,
  createMuiTheme,
  withStyles,
} from "@material-ui/core";
import {CheckCircleRounded, ErrorOutlineRounded} from "@material-ui/icons";
import {blue, grey} from "@material-ui/core/colors";

import React, {useState} from "react";
import {
  showNotification,
  UPDATE,
  withDataProvider,
} from 'react-admin';
import {withTranslate} from "ra-core";



const blueTheme = createMuiTheme({ palette: { primary: blue } })

const styles = theme => ({
  root: {
    marginTop: 50,
  },
  header: {
    padding: 12,
    borderTop: `2px ${grey[300]} solid`,
    borderBottom: `1px ${grey[300]} solid`,
    display: 'flex',
    justifyContent: 'space-between',
  },
  historySection: {
    padding: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    textTransform: 'none',
  },
  select: {
    border: '1px solid rgba(0, 0, 0, 0.42)',
    paddingRight: 10,
    paddingLeft: 10,
    background: 'transparent',
    width: '70%',
    fontFamily: '"Arial", sans-serif',

    '& select:focus': {
      background: 'transparent',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
});

const VersionHistory = ({ classes, record, dataProvider, translate, dispatch, ...props }) => {
  const [open, setOpenState] = useState(false);
  const [version, setVersion] = useState('');

  const toggleDialog = () => setOpenState(!open);
  const handleVersionChange = event => setVersion(event.target.value);
  const handleRollback = async () => {
    await dataProvider(UPDATE, 'articles', {
      id: record.id,
      rollback: true,
      data: { version },
    });

    setVersion('');
  };

  const handleCompare = () => {
    if (!record?.versionHistory?.length) {
      return dispatch(showNotification(translate('ra.pages.articles.notifications.compare_error'), 'warning'));
    }

    const versionToCompare = record.versionHistory.find(el => el.version === parseInt(version, 10));

    localStorage.setItem('currentVersion', record.text);
    localStorage.setItem('previousVersion', versionToCompare.data.text);

    window.open('/article-compare', '_blank');
  };

  return (
    <div className={classes.root}>
      <RollbackDialog
        open={open}
        handleClose={toggleDialog}
        handleRollback={handleRollback}
      />

      <div className={classes.header}>
        <Typography color="textSecondary">
          {translate("ra.pages.articles.fields.version_history")}
        </Typography>

        <MuiThemeProvider theme={blueTheme}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            className={classes.button}
            onClick={toggleDialog}
            disabled={!version}
          >
            {translate("ra.pages.articles.actions.rollback")}
          </Button>
        </MuiThemeProvider>
      </div>

      <div className={classes.historySection}>
        <Select
          native={true}
          className={classes.select}
          value={version}
          onChange={handleVersionChange}
        >
          <option value="" />
          {
            record?.versionHistory.map(el => {
              const date = new Date(el.date);

              return (
                <option value={el.version} key={el._id}>
                  {`v. ${el.version} published on ${date.toLocaleDateString()} ${date.toTimeString().split(' ')[0]}`}
                </option>
              );
            }).reverse()
          }
        </Select>

        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={classes.button}
          onClick={handleCompare}
          disabled={!version}
        >
          {translate("ra.pages.articles.actions.compare")}
        </Button>
      </div>
    </div>
  );
};


const dialogStyles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

const RollbackDialog = withTranslate(withStyles(dialogStyles)(({ translate, ...props }) => {
  const handleConfirm = async () => {
    await props.handleRollback();
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={true}
    >
      <DialogTitle>
        {translate("ra.pages.articles.rollback_dialog.title")}
      </DialogTitle>
      <DialogActions>
        <Button onClick={props.handleClose}>
          <ErrorOutlineRounded className={props.classes.leftIcon} />
          {translate("ra.pages.articles.rollback_dialog.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          <CheckCircleRounded className={props.classes.leftIcon} />
          {translate("ra.pages.articles.rollback_dialog.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}));

export default withTranslate(withDataProvider(withStyles(styles)(VersionHistory)));
