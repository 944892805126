import React, { useEffect, useState } from "react";
import Preview from "./Preview";
import Technology from "./Technology";
import Content from "./Content";
import "./workpage.scss";

const WorkPage = props => {
  const [work, setWork] = useState({});

  useEffect(() => {
    let wr = localStorage.getItem("work-preview");

    if (wr) {
      wr = JSON.parse(wr);
      if (wr.techStack) {
        let ts = [[], []];
        wr.techStack
          .trim()
          .split("\n")
          .map(el => {
            const ar = el.split(":");
            return {
              name: ar[0].trim() || "",
              list: ar[1] ? ar[1].split(" / ") : []
            };
          })
          .map((el, i) => {
            ts[i % 2].push(el);
          });
          wr.techStack = ts;
      }
      setWork({ ...wr });
    }
  }, [props.location.article]);

  return work.text ? (
    <main className="main-work-container">
      <Preview work={work} />
      <Technology stacks={work.techStack[0].length ? work.techStack : null} />
      <Content content={work.text} title={work.title} />
    </main>
  ) : null;
};

export default WorkPage;
