export const choices = translate => [
  {
    id: "draft",
    name: translate("ra.pages.common.fields.statuses.draft")
  },
  {
    id: "published",
    name: translate("ra.pages.common.fields.statuses.published")
  }
];

export const statuses = translate => ({
  draft: translate("ra.pages.common.fields.statuses.draft"),
  published: translate("ra.pages.common.fields.statuses.published")
});
