import React from "react";
import "./preview.scss";

const Preview = ({ work }) => {
  const { title, caseFiture, industry, team, duration, categories } = work;
  const casePictureUrl = caseFiture.photo;
  const category = categories.join(", ").replace(/_/g, " ");
  return (
    <section className="work-page-preview-container">
      <div className="title-container">
        <img src="/svg/section-red-line-69x1.svg" alt="red-title-line" />
        <h1>{"Case study"}</h1>
      </div>
      <div className="description">
        <div className="main-info">
          <h2>{title}</h2>

          <h3>Services</h3>
          <span>{category}</span>

          <h3>Industry</h3>
          <span>{industry}</span>

          {duration && (
            <>
              <h3>Duration</h3>
              <span>{duration}</span>
            </>
          )}
          {team && (
            <>
              <h3>Team</h3>
              <span>{team}</span>
            </>
          )}
        </div>
        <div className="case-picture">
          <img src={casePictureUrl} title={title} alt={caseFiture.altName} />
        </div>
      </div>
    </section>
  );
};

export default Preview;
