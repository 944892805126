export const validateWork = translate => values => {
  const requiredArr = value =>
    value && value.length ? undefined : translate("ra.validation.required");

  const validTitle = value =>
    value.match(/^[\s\+a-zA-Z0-9?:!,.%'$&—-]+$/)
      ? undefined
      : translate("ra.validation.only_latin");

  const maxLength = max => value =>
    value.length <= max
      ? undefined
      : translate("ra.validation.maxLength", { max });

  const requiredText = value =>
    value && value.length ? undefined : translate("ra.validation.required");

  const validURL = value =>
    value.match(/^[a-z0-9-]+$/)
      ? undefined
      : translate("ra.validation.url_not_valid");

  const validAppStoreURL = value =>
    value.match(/^(http(s)?:\/\/)?(itunes|apps)\.apple\.com\//)
      ? undefined
      : translate("ra.validation.a_s_url_not_valid");

  const validPlayMarketURL = value =>
    value.match(/^(http(s)?:\/\/)?play\.google\.com\//)
      ? undefined
      : translate("ra.validation.p_m_url_not_valid");

  const validColor = value =>
    value.match(/^#[a-f0-9]{6}/i)
      ? undefined
      : translate("ra.validation.color_not_valid");

  const validPriority = value => {
    console.log(typeof value, value);
    return value <= 0
      ? translate("ra.validation.priority_not_valid")
      : undefined;
  }


  const required = translate("ra.validation.required");

  const errors = {};

  if (values.status === "published") {
    //services
    if (requiredArr(values.services)) {
      errors.services = [requiredArr(values.services)];
    }

    //previewFeature
    if (!values.previewFeature) {
      errors.previewFeature = [required];
    }

    //industry
    if (!values.industry) {
      errors.industry = [required];
    }

    //duration
    if (!values.duration) {
      errors.duration = [required];
    }

    //teamSize
    if (!values.teamSize) {
      errors.teamSize = [required];
    }

    //description
    if (!values.description) {
      errors.description = [required];
    }

    //backgroundColor
    if (!values.backgroundColor) {
      errors.backgroundColor = [required];
    }

    //accentColor
    if (!values.accentColor) {
      errors.accentColor = [required];
    }

    //priority
    if (!values.hasOwnProperty('priority')) {
      errors.priority = [required];
    }
  }

  //title
  if (!values.title) {
    errors.title = [required];
  } else if (validTitle(values.title)) {
    errors.title = [validTitle(values.title)];
  } else if (maxLength(100)(values.title)) {
    errors.title = [maxLength(100)(values.title)];
  }

  //url
  if (!values.url) {
    errors.url = [required];
  } else if (validURL(values.url)) {
    errors.url = [validURL(values.url)];
  }

  //backgroundColor
  if (values.backgroundColor) {
    const colorValidMsg = validColor(values.backgroundColor);
    if (colorValidMsg) {
      errors.backgroundColor = [colorValidMsg];
    }
  }

  //accentColor
  if (values.accentColor) {
    const colorValidMsg = validColor(values.accentColor);
    if (colorValidMsg) {
      errors.accentColor = [colorValidMsg];
    }
  }

  //industry
  if (values.industry) {
    const maxLen = maxLength(30)(values.industry);
    if (maxLen) {
      errors.industry = [maxLen];
    }
  }

  //duration
  if (values.duration) {
    const maxLen = maxLength(100)(values.duration);
    if (maxLen) {
      errors.duration = [maxLen];
    }
  }

  //teamSize
  if (values.teamSize) {
    const maxLen = maxLength(100)(values.teamSize);
    if (maxLen) {
      errors.teamSize = [maxLen];
    }
  }

  //description
  if (values.description) {
    const maxLen = maxLength(600)(values.description);
    if (maxLen) {
      errors.description = [maxLen];
    }
  }

  //priority
  if (values.hasOwnProperty('priority')) {
    const priorityValidMsg = validPriority(values.priority);
    if (priorityValidMsg) {
      errors.priority = [priorityValidMsg];
    }
  }

  return errors;
};
