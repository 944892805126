import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  SaveButton as RAButton,
  showNotification
} from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import { styles } from "../../common/listStyles";
import { withStyles } from "@material-ui/core";
import { Clear, Visibility } from "@material-ui/icons";
import classnames from "classnames";
import DeleteButton from "../../common/DeleteButton";
import httpClient from "../../providers/httpClient";
import { connect } from "react-redux";
import { Field } from "./Field";
import { FileInput } from "./FileInput";
import { CustomDateField } from "../../common/CustomDateField";
import withTranslate from "ra-core/esm/i18n/translate";
import { ROUTE_NAME_REGEXP } from './constants';

const Actions = ({
  record,
  classes,
  ndata,
  nids,
  setNdata,
  setNids,
  showNotification,
  translate,
  ...props
}) => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const id = record.id;
    const { routeName, title, fileSize } = ndata[id];
    if (
      !(routeName && title && fileSize) ||
      !(
        routeName.length <= 120 &&
        routeName.match(ROUTE_NAME_REGEXP) &&
        title.length <= 100
      )
    ) {
      setDisabled(true);
    } else setDisabled(false);
  }, [record.id, ndata]);

  const save = () => {
    const id = record.id;
    const { routeName, title, fileSize, file } = ndata[id];
    if (routeName && title && fileSize) {
      if (
        routeName.length <= 120 &&
        routeName.match(ROUTE_NAME_REGEXP) &&
        title.length <= 100
      ) {
        setDisabled(true);
        const formData = new FormData();
        if (file) {
          formData.append("file", file, file.name);
        }
        formData.append("title", title);
        formData.append("routeName", routeName);
        if (id.includes("newfile")) {
          httpClient("/s3file", {
            method: "POST",
            body: formData
          })
            .then(res => {
              let n = ndata;
              delete n[id];

              let i = nids;
              i.splice(i.indexOf(id), 1);

              n[res.json.id] = res.json;
              i.unshift(res.json.id);

              setNdata({ ...n });
              setNids([...i]);

              showNotification(translate("ra.notification.saved"));
              setDisabled(false);
            })
            .catch(() => {
              showNotification(translate("ra.notification.wrong"), "warning");
              setDisabled(false);
            });
        } else {
          httpClient(`/s3file/${id}`, {
            method: "PUT",
            body: formData
          })
            .then(() => {
              let n = ndata;
              delete n[id].touched;
              setNdata({ ...n });
              showNotification(translate("ra.notification.saved"));
              setDisabled(false);
            })
            .catch(() => {
              showNotification(translate("ra.notification.wrong"), "warning");
              setDisabled(false);
            });
        }
      }
    }
  };

  const localDel = () => {
    const id = record.id;

    let n = ndata;
    delete n[id];

    let i = nids;
    i.splice(i.indexOf(id), 1);

    setNdata({ ...n });
    setNids([...i]);
  };

  const showFile = () => {
    window.open(`https://img.orangesoft.co/${record.routeName}`);
  };
  return (
    <>
      <RAButton
        label=""
        disabled={disabled || !(record.touched === true)}
        className={classes.saveGreen}
        onClick={save}
        icon={
          <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.7364 0.263531C17.385 -0.0879608 16.8151 -0.0879608 16.4636 0.263531L5.68106 11.0462L1.53642 6.90156C1.18496 6.55007 0.615147 6.5501 0.263619 6.90156C-0.087873 7.25302 -0.087873 7.82283 0.263619 8.17432L5.04466 12.9553C5.39601 13.3067 5.96624 13.3065 6.31746 12.9553L17.7364 1.53633C18.0879 1.18487 18.0879 0.615024 17.7364 0.263531Z"
              fill="#3EC20C"
            />
          </svg>
        }
      />
      <RAButton
        label=""
        disabled={record.id.includes("newfile") || record.touched}
        className={classes.orangeLittleButton}
        onClick={showFile}
        icon={<Visibility />}
      />
      {record && record.id.includes("newfile") ? (
        <RAButton
          label=""
          className={classnames(
            classes.orangeLittleButton,
            classes.cancelLittleButton
          )}
          onClick={localDel}
          icon={<Clear />}
        />
      ) : (
        <DeleteButton {...props} record={record} />
      )}
    </>
  );
};

const FileActions = ({
  classes,
  translate,
  ndata,
  setNdata,
  nids,
  setNids
}) => {
  const addNew = () => {
    let id = "newfile" + Number(new Date());
    setNdata({
      [id]: {
        title: "",
        routeName: "",
        id: id
      },
      ...ndata
    });
    setNids([id, ...nids]);
  };
  return (
    <Toolbar className={classes.customToolbar}>
      <button onClick={addNew}>
        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
          <g>
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
          </g>
        </svg>
        <span>{translate("ra.pages.files.actions.add")}</span>
      </button>
    </Toolbar>
  );
};

const CustomDatagrid = ({
  classes,
  showNotification,
  translate,
  data,
  ids,
  ...props
}) => {
  const [ndata, setNdata] = useState({});
  const [nids, setNids] = useState([]);

  useEffect(() => {
    setNids(ids);
    setNdata(data);
  }, [data, setNdata, ids, setNids]);
  return (
    <>
      <FileActions
        classes={classes}
        ndata={ndata}
        setNdata={setNdata}
        nids={nids}
        setNids={setNids}
        translate={translate}
      />
      <Datagrid
        className={classnames(
          classes.tableWrapper,
          classes.customTable,
          classes.filesTable
        )}
        data={ndata}
        ids={nids}
        {...props}
        total={100000}
      >
        <Field
          setNdata={setNdata}
          ndata={ndata}
          source="title"
          label={translate("ra.pages.files.fields.title")}
          sortable={false}
        />
        <Field
          setNdata={setNdata}
          ndata={ndata}
          source="routeName"
          sortable={false}
          label={translate("ra.pages.files.fields.route")}
        />
        <FileInput
          source="file"
          // accept="application/pdf"
          setNdata={setNdata}
          ndata={ndata}
          sortable={false}
          label={translate("ra.pages.files.fields.file")}
        />
        <CustomDateField
          source="createdAt"
          sortable={false}
          classes={classes}
          label={translate("ra.pages.files.fields.date")}
        />
        <Actions
          ndata={ndata}
          setNdata={setNdata}
          nids={nids}
          setNids={setNids}
          classes={classes}
          showNotification={showNotification}
          translate={translate}
        />
      </Datagrid>
    </>
  );
};

const FileList = ({ classes, showNotification, translate, ...props }) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      actions={null}
      className={classes.list}
      title={translate("ra.menu.files")}
      perPage={100000}
      pagination={null}
    >
      <CustomDatagrid
        classes={classes}
        showNotification={showNotification}
        translate={translate}
      />
    </List>
  );
};

export default connect(
  null,
  { showNotification }
)(withStyles(styles)(withTranslate(FileList)));
