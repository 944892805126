import React from "react";
import { List, Datagrid, TextField, CreateButton } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import { styles } from "../../common/listStyles";
import { withStyles } from "@material-ui/core";
import { withTranslate } from "ra-core";

const ArticlesActions = ({ basePath, classes, translate }) => (
  <Toolbar className={classes.toolbar}>
    <CreateButton
      basePath={basePath}
      label={translate("ra.pages.authors.actions.add")}
    />
  </Toolbar>
);

const CutomAvatar = ({ record: { photo, firstName } }) => (
  <Avatar alt={firstName} src={photo} />
);

const AuthorsList = ({ classes, translate, ...props }) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      actions={<ArticlesActions classes={classes} translate={translate} />}
      className={classes.list}
      title={translate("ra.menu.authors")}
    >
      <Datagrid rowClick="edit" className={classes.tableWrapper}>
        <CutomAvatar label="" source="photo" sortable={false} />
        <TextField
          label={translate("ra.pages.authors.fields.full_name")}
          source="fullName"
          sortable={false}
        />
        <TextField
          label={translate("ra.pages.authors.fields.num_posts")}
          source="count"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default withStyles(styles)(withTranslate(AuthorsList));
