export const styles = theme => ({
  tdStyle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.46429em"
  },
  list: {
    "& > div": {
      boxShadow: "none"
    },
    "& div[aria-haspopup='true']": {
      background: "#EEF6FE",
      borderRadius: 4
    }
  },
  toolbar: {
    right: 0,
    position: "absolute",
    padding: 0,
    "& a": {
      border: "1px solid rgba(232, 85, 31, 0.5)"
    }
  },
  filter: {
    marginLeft: 'auto',
    marginRight: '160px',
    "& > div > div": {
      marginTop: '0px'
    }
  },
  customToolbar: {
    padding: 0,
    justifyContent: "flex-end",
    "& button": {
      cursor: "pointer",
      background: "transparent",
      display: "flex",
      border: "1px solid rgba(232, 85, 31, 0.5)",
      outline: "none",
      padding: "7px 8px",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderRadius: 4,
      "& span": {
        color: "#e8551f",
        fontWeight: 500,
        fontSize: "0.8125rem",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: "1.5em",
        textTransform: "uppercase",
        paddingLeft: "0.4em"
      },
      "& svg": {
        fontSize: 20,
        width: "1em",
        height: "1em",
        fill: "#e8551f"
      }
    },
    "& button:hover": {
      backgroundColor: "rgba(232, 85, 31, 0.08)"
    }
  },
  orangeLittleButton: {
    width: 42,
    minWidth: 42,
    padding: "7px 8px",
    marginRight: 10,
    background: "transparent",
    border: "1px solid #e8551f",
    color: "#e8551f",
    boxShadow: "none",
    "& svg": {
      marginRight: 0,
      fontSize: 20
    },
    "&:hover": {
      background: "rgba(232, 85, 31, 0.08)"
    },
    "&:active": {
      boxShadow: "none"
    },
    "&:disabled": {
      opacity: 0.4,
      background: "transparent",
      color: "#e8551f"
    }
  },
  cancelLittleButton: {
    marginRight: 0
  },
  customTable: {
    "& thead": {
      background: "transparent !important",
      "& th": {
        borderBottom: "1px solid #EEF6FE !important",
        borderRight: "1px solid #EEF6FE !important",
        "&:last-child": {
          borderRight: "none !important",
          boxSizing: "border-box"
        }
      }
    },
    "& tbody": {
      "& tr": {
        "&:hover": {
          backgroundColor: "#F5F8FF !important"
        },
        verticalAlign: "text-top !important",
        "& td": {
          borderBottom: "1px solid #EEF6FE !important",
          borderRight: "1px solid #EEF6FE !important",
          "&:last-child": {
            borderRight: "none !important",
            boxSizing: "border-box"
          }
        },
        "&:last-child td": {
          borderBottom: "none !important"
        }
      }
    }
  },
  seoTable: {
    "& thead": {
      "& th": {
        "&:first-child": {
          width: 200
        },
        "&:nth-child(2)": {
          width: 360
        },
        "&:nth-child(3)": {
          minWidth: 360
        },
        "&:last-child": {
          minWidth: 118,
          width: 118
        }
      }
    },
    "& tbody": {
      "& tr": {
        "& td": {
          "&:first-child": {
            width: 200
          },
          "&:nth-child(2)": {
            width: 360
          },
          "&:nth-child(3)": {
            minWidth: 360
          },
          "&:last-child": {
            minWidth: 118,
            width: 118
          }
        }
      }
    }
  },
  filesTable: {
    "& thead": {
      "& th": {
        "&:last-child": {
          minWidth: 170,
          width: 170
        }
      }
    },
    "& tbody": {
      "& tr": {
        "& td": {
          "&:last-child": {
            minWidth: 170,
            width: 170
          }
        }
      }
    }
  },
  tableWrapper: {
    borderRadius: "4px 4px 0px 0px",
    border: "1px solid #EEF6FE",
    boxSizing: "border-box",
    borderCollapse: "initial",
    "& thead": {
      background: "#EEF6FE",
      "& th": {
        borderBottom: "none",
        fontSize: 15,
        color: "rgba(0, 0, 0, 0.87)",
        fontWeight: "bold"
      }
    },
    "& tbody": {
      "& td": {
        borderBottom: "none",
        "&.column-id": {
          "& > div": {
            width: "50px",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }
        }
      }
    }
  },
  capitalize: {
    textTransform: "capitalize"
  },
  saveGreen: {
    width: 42,
    minWidth: 42,
    padding: "7px 8px",
    marginRight: 10,
    background: "transparent",
    border: "1px solid #3EC20C",
    boxShadow: "none",
    "& svg": {
      marginRight: 0
    },
    "&:hover": {
      background: "#3EC20C15"
    },
    "&:active": {
      boxShadow: "none"
    },
    "&:disabled": {
      opacity: 0.4,
      background: "transparent"
    }
  },
  helperText: {
    "& p": {
      marginTop: 0,
      marginBottom: 10
    }
  }
});
