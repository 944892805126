import React from "react";
import Paper from "@material-ui/core/Paper";
import {
  SimpleForm,
  TextInput,
  showNotification,
  required,
  Toolbar,
  SaveButton,
  REDUX_FORM_NAME
} from "react-admin";
import { reset } from "redux-form";
import httpClient from "../../providers/httpClient";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { Label } from "../../common/Label";
import { withStyles, Typography } from "@material-ui/core";
import { withTranslate } from "ra-core";

const record = {
  oldPass: "",
  newPass: "",
  confirm: ""
};

const styles = theme => ({
  toolbar: {
    backgroundColor: "inherit",
    paddingTop: 24,
    paddingBottom: 24,
    [theme.breakpoints.down("xs")]: {
      padding: 16,
      backgroundColor: "#f5f5f5"
    }
  },
  form: {
    maxWidth: 600
  },
  login: {
    fontWeight: 400,
    whiteSpace: "pre",
    marginTop: 16,
    marginBottom: 8
  },
  noLabel: {
    "& label": {
      display: "none"
    }
  },
  saveButton: {
    textTransform: "none",
    width: 85,
    "& span": {
      "& div": {
        display: "none"
      }
    },
    "& svg": {
      display: "none"
    }
  },
  settingsTitle: {
    fontWeight: "500",
    fontSize: 22,
    position: "absolute",
    top: 11,
    zIndex: 10000,
    left: 72,
    color: "#fff",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    [theme.breakpoints.down("xs")]: {
      top: 15
    }
  }
});

const CustomToolbar = ({ classes, ...props }) => {
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton className={classes.saveButton} />
    </Toolbar>
  );
};

const Settings = props => {
  const { classes, translate } = props;
  const handleSave = () => {
    const {
      values: { oldPass, newPass, confirm },
      reset,
      showNotification
    } = props;
    httpClient("/user/changepass", {
      method: "PUT",
      body: JSON.stringify({
        oldPass,
        newPass,
        confirm
      })
    })
      .then(res => {
        showNotification(res.json.message);
        reset(REDUX_FORM_NAME);
      })
      .catch(err => {
        showNotification(err.message, "warning");
      });
  };

  const equal = (message = translate("ra.validation.equal")) => value => {
    const { newPass } = props.values;
    return value === newPass ? undefined : message;
  };

  const equalNewAndOld = (
    message = translate("ra.validation.equal_new_and_old")
  ) => value => {
    const { oldPass } = props.values;
    return value !== oldPass ? undefined : message;
  };
  return (
    <Paper elevation={1} className={classes.form}>
      <span className={classes.settingsTitle}>
        {translate("ra.menu.settings")}
      </span>
      <SimpleForm
        toolbar={<CustomToolbar classes={classes} />}
        record={record}
        save={handleSave}
      >
        <Typography variant="h4" component="h4" className={classes.login}>
          {`${translate(
            "ra.pages.settings.fields.login"
          )}    ${localStorage.getItem("login") || ""}`}
        </Typography>
        <TextInput
          source="oldPass"
          validate={[required()]}
          InputProps={{
            type: "password",
            startAdornment: (
              <Label val={translate("ra.pages.settings.fields.old_password")} />
            )
          }}
          fullWidth
          className={classes.noLabel}
          placeholder={translate("ra.pages.settings.placeholders.old_password")}
        />
        <TextInput
          source="newPass"
          validate={[required(), equalNewAndOld()]}
          InputProps={{
            type: "password",
            startAdornment: (
              <Label val={translate("ra.pages.settings.fields.new_password")} />
            )
          }}
          fullWidth
          className={classes.noLabel}
          placeholder={translate("ra.pages.settings.placeholders.new_password")}
        />
        <TextInput
          source="confirm"
          validate={[required(), equal()]}
          InputProps={{
            type: "password",
            startAdornment: (
              <Label
                val={translate("ra.pages.settings.fields.confirm_password")}
              />
            )
          }}
          fullWidth
          className={classes.noLabel}
          placeholder={translate(
            "ra.pages.settings.placeholders.confirm_password"
          )}
        />
      </SimpleForm>
    </Paper>
  );
};

export default connect(
  state => ({
    values: getFormValues(REDUX_FORM_NAME)(state)
  }),
  { showNotification, reset }
)(withStyles(styles)(withTranslate(Settings)));
