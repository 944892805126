import React from "react";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import { Label } from "../../common/Label";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  SaveButton,
  SimpleForm,
  Toolbar as ModalToolbar,
  TextInput,
  TextField,
  ImageField,
  required,
  Edit,
  maxLength
} from "react-admin";
import { withStyles } from "@material-ui/core";
import DeleteButton from "../../common/DeleteButton";
import { reset } from "redux-form";
import { connect } from "react-redux";
import withTranslate from "ra-core/esm/i18n/translate";

const styles = theme => ({
  dialog: {
    "& .ra-input-date, .ra-input-fileType, .ra-input-fileSize, .ra-input-fileResolution": {
      width: "50%",
      display: "inline-block"
    },
    '& div[role="toolbar"]': {
      display: "flex",
      justifyContent: "space-between"
    },
    "& form > div:last-child": {
      height: 0
    }
  },
  noLabel: {
    "& label": {
      display: "none"
    }
  },
  closeButton: {
    position: "absolute",
    right: 2,
    top: 2,
    color: theme.palette.grey[500]
  },
  modalToolbar: {
    backgroundColor: "inherit",
    [theme.breakpoints.down("xs")]: {
      position: "static"
    }
  },
  saveButton: {
    textTransform: "none",
    width: 85,
    "& span": {
      "& div": {
        display: "none"
      }
    },
    "& svg": {
      display: "none"
    }
  },
  linkWrapper: {
    marginTop: 16,
    marginBottom: 24
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none"
  },
  mediaField: {
    borderRadius: 4,
    height: 150,
    width: 200,
    background: "#EEF6FE",
    "& div": {
      margin: 0,
      padding: 0
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "scale-down",
      cursor: "pointer",
      margin: 0
    },
    "& video": {
      width: "100%",
      height: "100%",
      objectFit: "scale-down",
      cursor: "pointer",
      margin: 0
    }
  }
});

const renderLinks = (list, classes, source) => {
  const getLink = (source, id) => {
    let path;
    switch (source) {
      case 'infographic':
      case 'infographicPreview':
        path = `/infographics`;
        break;
      case 'workCases':
      case 'workCasePreview':
        path =  `/work-cases`;
        break;
      default:
        path =  `/articles`;
    }

    return `${path}/${id}`
  }

  return list.map((el, ind, arr) => (
    <span key={ind}>
      <Link
        to={getLink(source, el.id)}
        className={classes.link}
      >
        {el.title}
      </Link>
      {ind !== arr.length - 1 ? ", " : ""}
    </span>
  ));
};

const Links = React.memo(({ record, source, classes, translate }) => {
  const getLabel = source => {
    switch (source) {
      case 'articles':
        return translate("ra.pages.media.in_articles");
      case 'previewFor':
        return translate("ra.pages.media.preview_f_a");
      case 'workCases':
        return translate("ra.pages.media.in_work_cases");
      case 'workCasePreview':
        return translate("ra.pages.media.preview_f_cs");
      case 'heroImageFor':
        return translate("ra.pages.media.as_hero_image");
      case 'infographic':
        return translate("ra.pages.media.infographic");
      case 'infographicPreview':
        return translate("ra.pages.media.infographic_preview");
      default:
        return '';
    }
  }

  return record[source] && record[source].length ? (
    <div className={classes.linkWrapper}>
      {getLabel(source)}
      {renderLinks(record[source], classes, source)}
    </div>
  ) : null;
});

const Title = React.memo(({ record, translate }) => {
  return (
    <>{` ${translate("ra.pages.media.edit_media_heading")} ${record.name}`}</>
  );
});

const CustomToolbar = React.memo(({ classes, ...props }) => {
  return (
    <ModalToolbar {...props} className={classes.modalToolbar}>
      <SaveButton className={classes.saveButton} />
      {!(
        (props.record.articles && props.record.articles.length) ||
        (props.record.previewFor && props.record.previewFor.length) ||
        (props.record.workCases && props.record.workCases.length) ||
        (props.record.workCasePreview && props.record.workCasePreview.length)
      ) && <DeleteButton />}
    </ModalToolbar>
  );
});

const EditMedia = ({ classes, record, setOpen, setRecord, translate }) => {
  const handleClose = () => {
    reset("mediaEdit");
    setOpen(false);
    setRecord({});
  };

  const openImage = url => {
    window.open(url, "_blank");
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <Edit
        undoable={false}
        resource="media"
        id={record.id || ""}
        basePath="media"
        title={<Title translate={translate} />}
      >
        <SimpleForm
          toolbar={<CustomToolbar classes={classes} />}
          form="mediaEdit"
        >
          {record.fileType === "video/mp4" ? (
            <div
              className={classes.mediaField}
              onClick={() => openImage(record.url)}
            >
              <video>
                <source src={record.url} type="video/mp4" />
              </video>
            </div>
          ) : (
            <ImageField
              label=""
              source="url"
              record={record}
              className={classes.mediaField}
              onClick={() => openImage(record.url)}
            />
          )}
          <TextInput
            source="name"
            validate={[required(), maxLength(50)]}
            fullWidth
            className={classes.noLabel}
            placeholder={translate("ra.pages.media.placeholders.name")}
            InputProps={{
              startAdornment: (
                <Label val={translate("ra.pages.media.fields.name") + " *"} />
              )
            }}
          />
          <TextInput
            source="altName"
            validate={[required(), maxLength(50)]}
            fullWidth
            className={classes.noLabel}
            placeholder={translate("ra.pages.media.placeholders.alt_name")}
            InputProps={{
              startAdornment: (
                <Label
                  val={translate("ra.pages.media.fields.alt_name") + " *"}
                />
              )
            }}
          />
          <TextField
            label={translate("ra.pages.media.fields.url")}
            source="url"
            fullWidth
            record={record}
          />
          <TextField
            label={translate("ra.pages.media.fields.date")}
            source="date"
            record={record}
          />
          <TextField
            label={translate("ra.pages.media.fields.file_type")}
            source="fileType"
          />
          <TextField
            label={translate("ra.pages.media.fields.file_size")}
            source="fileSize"
          />
          <TextField
            label={translate("ra.pages.media.fields.file_resolution")}
            source="fileResolution"
          />
          <Links source="previewFor" classes={classes} translate={translate} />
          <Links source="articles" classes={classes} translate={translate} />
          <Links
            source="workCasePreview"
            classes={classes}
            translate={translate}
          />
          <Links source="workCases" classes={classes} translate={translate} />
          <Links source="heroImageFor" classes={classes} translate={translate} />
          <Links source="infographic" classes={classes} translate={translate} />
          <Links source="infographicPreview" classes={classes} translate={translate} />
        </SimpleForm>
      </Edit>
    </Dialog>
  );
};

export default withStyles(styles)(
  withTranslate(
    React.memo(
      connect(
        null,
        { reset }
      )(EditMedia)
    )
  )
);
