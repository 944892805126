import React from "react";
import { Create, REDUX_FORM_NAME } from "react-admin";
import { change, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import withTranslate from "ra-core/esm/i18n/translate";
import Form from "./Form";

const styles = theme => ({
  form: {
    marginRight: 300,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0
    },
    "& form": {
      paddingBottom: 40
    },
    "& form > div:nth-child(2)": {
      boxShadow:
        "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
      height: 190,
      alignItems: "flex-end",
      borderRadius: 4,
      background: "transparent",
      position: "absolute",
      top: 0,
      right: -300,
      width: 276,
      margin: 0,
      boxSizing: "border-box",
      paddingBottom: 16,
      [theme.breakpoints.down("sm")]: {
        position: "static",
        width: "100%",
        boxShadow: "none",
        paddingTop: 24,
        paddingBottom: 24,
        backgroundColor: "inherit",
        marginTop: 16,
        minHeight: 64,
        boxSizing: "border-box",
        height: "auto",
        borderRadius: 0
      },
      [theme.breakpoints.down("xs")]: {
        padding: 16,
        backgroundColor: "#f5f5f5",
        left: 0,
        right: 0,
        top: "auto",
        width: "100%",
        bottom: "0",
        borderRadius: 0,
        zIndex: 2,
        position: "fixed",
        height: 64
      }
    }
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      width: "47%"
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      "& > div": {
        width: "100%"
      }
    }
  },
  noLabel: {
    "& label": {
      display: "none"
    }
  },
  labelCategories: {
    "& label": {
      transform: "translate(0, 1.5px)",
      color: "#000 !important"
    }
  },
  author: {
    width: "100%",
    "& label": {
      transform: "translate(0, 24px)",
      color: "#000 !important",
      "& span": {
        width: 50,
        display: "inline-block",
        height: 16,
        overflow: "hidden"
      }
    },
    "& input": {
      paddingLeft: 70
    }
  },
  status: {
    position: "absolute",
    zIndex: "100",
    top: 0,
    right: -276,
    width: 228,
    margin: 0,
    "& label": {
      transform: "translate(0, 24px)",
      color: "#000 !important",
      top: "auto",
      left: "auto"
    },
    "& > div": {
      paddingLeft: 65
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      width: "100%",
      marginTop: 16,
      marginBottom: 8
    }
  },
  type: {
    position: "absolute",
    zIndex: "100",
    top: 65,
    right: -276,
    width: 228,
    margin: 0,
    "& label": {
      transform: "translate(0, 24px)",
      color: "#000 !important",
      top: "auto",
      left: "auto"
    },
    "& > div": {
      paddingLeft: 65
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      width: "100%",
      marginTop: 16,
      marginBottom: 8
    }
  },
  previewResolution: {
    position: "absolute",
    zIndex: "100",
    top: 195,
    right: -300,
    width: 276,
    margin: 0,
    "& label": {
      transform: "translate(0, 24px)",
      color: "#000 !important",
      top: "auto",
      left: "auto"
    },
    "& > div": {
      paddingLeft: 140
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      width: "100%",
      marginTop: 16,
      marginBottom: 8
    }
  },
  addMediaButton: {
    textTransform: "none",
    zIndex: "100",
    position: "absolute",
    top: 265,
    right: -300,
    width: 276,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      position: "static",
      marginTop: 45,
      marginBottom: 8
    }
  },
  addPhotoCaseButton: {
    top: 895
  },
  addLargePreviewButton: {
    top: 580
  },
  addMedia: {
    position: "absolute",
    top: 270,
    right: -300,
    width: 276,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      position: "static",
      marginTop: -8,
      marginBottom: 0,
      "& p": {
        margin: 0
      }
    },
    "& .previews button": {
      zIndex: 1000000
    },
    "& > div": {
      padding: 0,
      "& > span > div:first-child": {
        visibility: "hidden",
        height: 37,
        boxSizing: "border-box",
        [theme.breakpoints.down("sm")]: {
          height: 0,
          padding: 0
        }
      },
      "& > span > div:first-child:hover": {
        backgroundColor: "rgba(232, 85, 31, 0.08)"
      }
    },
    "& label": {
      display: "none"
    },
    "& svg": {
      fill: theme.palette.primary.main
    }
  },
  addLargePreviewPhoto: {
    top: 585
  },
  addCasePhoto: {
    top: 900
  },
  addIcon: {
    fontSize: 20,
    marginRight: 6
  },
  closeButton: {
    position: "absolute",
    right: 2,
    top: 2,
    color: theme.palette.grey[500],
    zIndex: 100
  },
  colorWidth: {
    width: '100%'
  }
});

const WorksCreate = ({ classes, values, change, translate, ...props }) => {
  return (
    <Create
      {...props}
      className={classes.form}
      title={translate("ra.pages.works.create_work_heading")}
    >
      <Form
        change={change}
        classes={classes}
        translate={translate}
        values={values}
      />
    </Create>
  );
};

export default connect(
  state => ({
    values: getFormValues(REDUX_FORM_NAME)(state)
  }),
  { change }
)(withStyles(styles)(withTranslate(WorksCreate)));
