import React from "react";

export default function Video({ src, width = 276, className }) {
  const onMouseEnter = e => {
    e.target.play();
  };
  const onMouseLeave = e => {
    e.target.pause();
    e.target.currentTime = 0;
  };
  return (
    <video
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      width={width}
      loop
      className={className}
      src={src}
    >
      <source src={src} type="video/mp4" />
    </video>
  );
}
