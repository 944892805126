import React from "react";
import { Admin, Resource } from "react-admin";
import dataProvider from "./providers/dataProvider";
import authProvider from "./providers/authProvider";
import i18nProvider from "./providers/i18nProvider";
import { createBrowserHistory } from "history";
import Login from "./layout/Login";
import Layout from "./layout/Layout";
import customRoutes from "./routes";
import articles from "./pages/articles";
import works from "./pages/works";
import infographics from "./pages/infographics";
import media from "./pages/media";
import authors from "./pages/authors";
import emails from "./pages/emails";
import seo from "./pages/seo";
import s3file from "./pages/s3file";

import theme from "./layout/theme";
const history = createBrowserHistory();
function App() {
  return (
    <Admin
      theme={theme}
      title="Orangesoft-admin"
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={Login}
      appLayout={Layout}
      history={history}
      i18nProvider={i18nProvider}
      locale={window.location.origin.slice(-3) === ".by" ? "ru" : "en"}
      customRoutes={customRoutes}
    >
      {() => [
        <Resource name="articles" {...articles} />,
        <Resource name="work-cases" {...works} />,
        <Resource name="infographics" {...infographics} />,
        <Resource name="media" {...media} />,
        <Resource name="emails" {...emails} />,
        <Resource name="authors" {...authors} />,
        <Resource name="seo" {...seo} />,
        <Resource name="s3file" {...s3file} />
      ]}
    </Admin>
  );
}

export default App;
