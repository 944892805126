import React, { useState, useEffect } from "react";
import { addField } from "ra-core";
import { withStyles, TextField } from "@material-ui/core";
import classnames from "classnames";
import { REDUX_FORM_NAME } from "react-admin";

const styles = theme => ({
  seoTitle: {
    "& textarea": {
      minHeight: "42px !important"
    }
  },
  bio: {
    "& textarea": {
      minHeight: "100px !important"
    }
  },
  techStack: {
    "& textarea": {
      minHeight: "78px !important"
    }
  },
  description: {
    "& textarea": {
      minHeight: "42px !important"
    }
  },
  text: {
    "& textarea": {
      minHeight: "126px !important"
    }
  },
  wrapper: {
    marginTop: 38,
    marginBottom: 8,
    "& > span": {
      color: "#000"
    },
    "& > div": {
      border: "1px solid rgba(0, 0, 0, 0.42)",
      borderRadius: 4,
      marginTop: 6,
      padding: "0 10px",
      "& > div": {
        border: "1px solid transparent"
      }
    },
    "& p": {
      margin: 0,
      fontSize: "0.75rem",
      marginTop: 8,
      minHeight: "1em",
      lineHeight: "1em",
      color: theme.palette.error.main
    },
    "&:focus-within": {
      "& > div": {
        border: `2px solid ${theme.palette.primary.dark}`,
        "& > div": {
          border: "none"
        }
      }
    },
    "&:hover": {
      "& > div": {
        border: `2px solid rgba(0, 0, 0, 0.87)`,
        "& > div": {
          border: "none"
        }
      }
    }
  },
  counter: {
    float: "right"
  },
  black: {
    color: "#000 !important"
  },
  margin: {
    marginTop: "-12px !important"
  },
  error: {
    "& > div": {
      border: `2px solid ${theme.palette.error.main} !important`,
      "& > div": {
        border: "none"
      }
    },
    "&:hover": {
      "& > div": {
        border: `2px solid ${theme.palette.error.main}`,
        "& > div": {
          border: "none"
        }
      }
    }
  }
});

const CustomOutlinedTextInput = ({
  classes,
  record,
  change,
  meta: { touched, error },
  source,
  label,
  maxLen = 600
}) => {
  const [val, setVal] = useState(record[source] ? record[source] : "");

  const handleChange = e => {
    setVal(e.target.value);
    change(REDUX_FORM_NAME, source, e.target.value);
  };

  useEffect(() => {
    setVal(record[source]);
  }, [record[source]]);

  return (
    <div
      className={classnames(
        classes.wrapper,
        !!(touched && error) ? classes.error : null
      )}
    >
      <span>{label}</span>
      <div className={classes[source]}>
        <TextField
          fullWidth
          multiline
          onChange={handleChange}
          value={val}
          InputProps={{ disableUnderline: true }}
          onBlur={handleChange}
        />
      </div>

      {!!(touched && error) && <p>{error}</p>}

      {source === "description" && (
        <p
          className={classnames(
            val && val.length <= 600 && classes.black,
            classes.counter,
            !!(touched && error) && classes.margin
          )}
        >
          {val && `${val.length} / ${maxLen}`}
        </p>
      )}
    </div>
  );
};

export const OutlinedTextInput = React.memo(
  withStyles(styles)(addField(CustomOutlinedTextInput))
);
