export default {
  ra: {
    action: {
      add_filter: "Добавить фильтр",
      add: "Добавить",
      back: "Назад",
      bulk_actions:
        "1 выбран |||| %{smart_count} выбрано |||| %{smart_count} выбрано",
      cancel: "Отмена",
      clear_input_value: "Очистить",
      clone: "Дублировать",
      confirm: "Подтвердить",
      create: "Создать",
      delete: "Удалить",
      edit: "Редактировать",
      export: "Экспортировать",
      list: "Список",
      refresh: "Обновить",
      remove_filter: "Убрать фильтр",
      remove: "Удалить",
      save: "Сохранить",
      search: "Поиск",
      show: "Просмотр",
      sort: "Сортировка",
      undo: "Отменить",
      expand: "Раскрыть",
      close: "Закрыть"
    },
    boolean: {
      true: "Да",
      false: "Нет",
      null: ""
    },
    page: {
      create: "Создать %{name}",
      dashboard: "Главная",
      edit: "%{name} #%{id}",
      error: "Что-то пошло не так",
      list: "%{name}",
      loading: "Загрузка",
      not_found: "Не найдено",
      show: "%{name} #%{id}",
      empty: "Нет %{name}.",
      invite: "Вы хотите добавить еще одну?"
    },
    input: {
      file: {
        upload_several: "Перетащите файлы сюда или нажмите для выбора.",
        upload_single: "Перетащите файл сюда или нажмите для выбора."
      },
      image: {
        upload_several: "Перетащите изображения сюда или нажмите для выбора.",
        upload_single: "Перетащите изображение сюда или нажмите для выбора."
      },
      references: {
        all_missing: "Связанных данных не найдено",
        many_missing: "Некоторые из связанных данных не доступны",
        single_missing: "Связанный объект не доступен"
      },
      password: {
        toggle_visible: "Скрыть пароль",
        toggle_hidden: "Показать пароль"
      }
    },
    message: {
      about: "Справка",
      are_you_sure: "Вы уверены?",
      bulk_delete_content:
        "Вы уверены, что хотите удалить %{name}? |||| Вы уверены, что хотите удалить объекты, кол-вом %{smart_count} ? |||| Вы уверены, что хотите удалить объекты, кол-вом %{smart_count} ?",
      bulk_delete_title:
        "Удалить %{name} |||| Удалить %{smart_count} %{name} |||| Удалить %{smart_count} %{name}",
      delete_content: "Вы уверены что хотите удалить этот объект",
      delete_title: "Удалить %{name} #%{id}",
      details: "Описание",
      error: "В процессе запроса возникла ошибка, и он не может быть завершен",
      invalid_form: "Форма заполнена неверно, проверьте, пожалуйста, ошибки",
      loading: "Идет загрузка, пожалуйста, подождите...",
      no: "Нет",
      not_found: "Ошибка URL или вы следуете по неверной ссылке",
      yes: "Да"
    },
    navigation: {
      no_results: "Результатов не найдено",
      no_more_results:
        "Страница %{page} выходит за пределы нумерации, попробуйте предыдущую",
      page_out_of_boundaries: "Страница %{page} вне границ",
      page_out_from_end: "Невозможно переместиться дальше последней страницы",
      page_out_from_begin: "Номер страницы не может быть меньше 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} из %{total}",
      page_rows_per_page: "Строк на странице:",
      next: "Следующая",
      prev: "Предыдущая"
    },
    auth: {
      auth_check_error: "Пожалуйста, авторизуйтесь для продолжения работы",
      user_menu: "Профиль",
      username: "Имя пользователя",
      password: "Пароль",
      sign_in: "Войти",
      sign_in_error: "Ошибка аутентификации, попробуйте снова",
      logout: "Выйти"
    },
    notification: {
      updated:
        "Элемент обновлен |||| %{smart_count} обновлено |||| %{smart_count} обновлено",
      created: "Элемент создан",
      deleted:
        "Элемент удален |||| %{smart_count} удалено |||| %{smart_count} удалено",
      bad_item: "Элемент не валиден",
      item_doesnt_exist: "Элемент не существует",
      http_error: "Ошибка сервера",
      data_provider_error: "Ошибка dataProvider, проверьте консоль",
      i18n_error: "Не удалось загрузить перевод для указанного языка",
      canceled: "Операция отменена",
      logged_out:
        "Ваша сессия завершена, попробуйте переподключиться/войти снова",
      wrong: "Что-то пошло не так",
      saved: "Сохранено",
      copied: "Скопировано",
      required_fields: "Не все обязательные поля заполнены"
    },
    validation: {
      required: "Обязательно для заполнения",
      minLength: "Минимальное кол-во символов %{min}",
      maxLength: "Максимальное кол-во символов %{max}",
      minValue: "Минимальное значение %{min}",
      maxValue: "Значение может быть %{max} или меньше",
      number: "Должно быть цифрой",
      email: "Некорректный email",
      oneOf: "Должно быть одним из: %{options}",
      regex: "Должно быть в формате (regexp): %{pattern}",
      equal: "Новый пароль и пароль подтверждения не совпадают",
      equal_new_and_old: "Новый пароль не может совпадать со старым паролем",
      url_not_valid: "Не валидный URL",
      only:
        "Пожалуйста, используйте только цифры, строчные латинские буквы или символы",
      only_latin: "Пожалуйста, используйте только латинские буквы",
      a_s_url_not_valid: "Не валидный App Store URL",
      p_m_rl_not_valid: "Не валидный Play Market URL",
      color_not_valid: "Неверный формат кода цвета",
      priority_not_valid: "Значение должно быть больше чем 0"
    },
    menu: {
      articles: "Статьи",
      works: "Проекты",
      media: "Медиа",
      emails: "Рассылка",
      authors: "Авторы",
      files: "Файлы",
      seo: "SEO",
      robots: "Robots",
      settings: "Настройки",
      infographics: 'Инфографики'
    },
    pages: {
      articles: {
        edit_article_heading: "Редактирование статьи",
        create_article_heading: "Создание статьи",
        actions: {
          add: "Добавить статью",
          preview: "Превью",
          add_question: "Добавить вопрос",
          add_preview_picture: "Картинка для превью",
          add_hero_picture: "Картинка для статьи",
          rollback: "Восстановить выбранную версию",
          compare: "Сравнить"
        },
        fields: {
          id: 'ID',
          title: "Заголовок",
          date: "Дата",
          lastUpdated: "Последнее обновление",
          categories: "Категории",
          author: "Автор",
          url: "URL",
          article_text: "Текст статьи",
          seo_title: "SEO-заголовок",
          description: "Описание",
          add_faq: "Добавить FAQ",
          question: "Вопрос:",
          answer: "Ответ:",
          status: "Статус",
          read_time: "Время прочтения (минут):",
          version_history: "История версий"
        },
        placeholders: {
          title: "Введите заголовок"
        },
        categories: {
          client_guides: "Полезные советы",
          tech: "Технологии",
          design: "Дизайн",
          startups: "Стартапы",
          orangesoft: "Orangesoft"
        },
        rollback_dialog: {
          title: "Вы уверены?",
          confirm: "Да",
          cancel: "Закрыть"
        },
        notifications: {
          compare_error: "Что-то пошло не так. Невозможно восстановить версию.",
        }
      },
      infographics: {
        create_heading: 'Создать инфографику',
        edit_heading: 'Редактировать инфографику',
        actions: {
          add_preview_picture: 'Картинка для превью',
          add_graphic: 'Картинка для инфографики',
          add: 'Создать инфографику'
        },
        placeholders: {
          title: "Введите заголовок"
        },
        fields: {
          id: 'ID',
          title: "Заголовок",
          date: "Дата",
          url: "URL",
          text: "Текст",
          seo_title: "SEO-заголовок",
          description: "Описание",
          status: "Статус",
        }
      },
      works: {
        edit_work_heading: "Редактирование проекта",
        create_work_heading: "Создание проекта",
        actions: {
          add: "Добавить проект",
          preview: "Превью",
          add_small_preview_picture: "Маленькая картинка для превью",
          add_large_preview_picture: "Большая картинка для превью",
          add_case_picture: "Картинка для кейс-стади"
        },
        fields: {
          case_study: "Кейс-стади",
          title: "Заголовок",
          categories: "Категории",
          services: "Сервисы",
          priority: "Приоритет",
          date: "Дата",
          status: "Статус",
          type: "Тип",
          url: "URL",
          industry: "Отрасль",
          duration: "Продлжительность",
          teamSize: "Размер команды",
          tech_stack: "Технологии",
          work_case_text: "Кейс-стади",
          app_store_url: "App Store URL",
          play_market_url: "Play Market URL",
          seo_title: "SEO-заголовок",
          description: "Описание",
          preview_size: "Размер превью"
        },
        placeholders: {
          title: "Введите заголовок",
          industry: "Укажите отрасль",
          duration: "Укажите продолжительность проекта",
          team: "Опишите команду",
          app_store_url: "Введите URL",
          play_market_url: "Введите URL",
          backgroundColor: "Фоновый цвет",
          accentColor: "Акцентирующий цвет"
        }
      },
      media: {
        edit_media_heading: "редактирование",
        create_media_heading: "Создание медиа",
        in_articles: "В статьях: ",
        in_work_cases: "В кейс-стади: ",
        preview_f_a: "Превью для статей: ",
        preview_f_cs: "Превью для кейс-стади: ",
        as_hero_image: "В заголовке: ",
        list: "Список",
        add_new: "Создание медиа",
        actions: {
          add: "Добавить медиа",
          pick_media: "Выбрать медиа"
        },
        fields: {
          name: "Заголовок",
          alt_name: "Alt-заголовок",
          url: "URL",
          date: "Дата добавления",
          file_type: "Тип файла",
          file_size: "Размер",
          file_resolution: "Расширение"
        },
        placeholders: {
          name: "Введите заголовок",
          alt_name: "Введите alt-заголовок"
        }
      },
      emails: {
        fields: {
          email: "Email",
          date: "Дата добавления"
        }
      },
      authors: {
        edit_author_heading: "Редактирование автора",
        create_author_heading: "Создание автора",
        actions: {
          add: "Добавить автора",
          pick_avatar: "Выбрать фото профиля"
        },
        fields: {
          full_name: "Автор",
          num_posts: "Количество статей",
          first_name: "Имя",
          last_name: "Фамилия",
          linkedin_url: "Linkedin URL",
          website_url: "Вебсайт",
          bio: "Био",
          jobTitle: "Должность"
        },
        placeholders: {
          first_name: "Введите имя",
          last_name: "Введите фамилию",
          linkedin_url: "Введите URL",
          website_url: "Введите URL"
        }
      },
      files: {
        actions: {
          add: "Добавить презентацию",
          upload: "Выбрать",
          uploaded: "Загружен"
        },
        fields: {
          title: "Заголовок",
          route: "Путь",
          file: "Файл",
          date: "Дата"
        }
      },
      seo: {
        actions: {
          add: "Добавить страницу"
        },
        fields: {
          route: "Путь",
          title: "Заголовок",
          description: "Описание"
        }
      },
      settings: {
        fields: {
          login: "Логин",
          old_password: "Старый пароль",
          new_password: "Новый пароль",
          confirm_password: "Подтвердите пароль"
        },
        placeholders: {
          old_password: "Введите старый пароль",
          new_password: "Введите новый пароль",
          confirm_password: "Подтвердите пароль"
        }
      },
      common: {
        fields: {
          statuses: {
            draft: "Черновик",
            published: "Опубликовано"
          },
          types: {
            na: "N/A",
            custom: "Кастомный",
            template: "Шаблон"
          }
        }
      }
    }
  }
};
