import React from "react";
import "./content.scss";
const rebuildOembed = htmlText => {
  const oembedRegExp = new RegExp(
    '<figure class="media"><oembed url="(.*?)">.*?</oembed></figure>',
    "g"
  );
  const oembeds = htmlText.match(oembedRegExp);
  if (oembeds) {
    const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    for (const oembed of oembeds) {
      const match = oembed
        .replace(/^.+url="(.*?)".+$/, "$1")
        .match(youtubeRegExp);
      if (match && match[2]) {
        htmlText = htmlText.replace(
          oembedRegExp,
          `<div class="media"><iframe src="https://youtube.com/embed/${
            match[2]
          }" width="100%" height="450px" frameborder="0" allowfullscreen></iframe></div>`
        );
      }
    }
  }
  return htmlText;
};
const Content = ({ content }) => {
  let almostCorrectContent = rebuildOembed(content);
  const correctContent = almostCorrectContent.replace(
    /style="margin-left:0px;"/g,
    ""
  );

  return (
    <section className="article-content-container">
      <div
        id="content"
        dangerouslySetInnerHTML={{ __html: correctContent }}
      ></div>
      <div className="content-end-line"></div>
    </section>
  );
};

export default Content;
