import {withStyles} from "@material-ui/core";
import {ImageInput} from "react-admin";
import SubImageField from "./SubImageField";
import React from "react";

const styles = {
  dropZone: {
    display: 'none',
  },
  root: {
    width: '100%',
    alignItems: 'center',
    '& label': {
      display: 'none'
    },
    '& div': {
      padding: 0,
      width: 'auto'
    },
    "& button": {
      display: "none"
    },
  },
};

const CustomImageInput = ({ classes, reduxFormName }) => {
  return (
    <ImageInput
      accept="image/*"
      classes={classes}
      source={`${reduxFormName}Feature`}
    >
      <SubImageField />
    </ImageInput>
  );
};

export default withStyles(styles)(CustomImageInput);
