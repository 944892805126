import React from "react";
import { ImageField } from "react-admin";
import { withStyles } from "@material-ui/core";
import Video from "./Video";

const styles = theme => ({
  image: {
    "& img": {
      width: '100%',
      margin: 0,
      borderRadius: 4,
      maxHeight: 350,
      objectFit: "scale-down",
      cursor: "pointer"
    }
  },
  video: {
    margin: 0,
    borderRadius: 4,
    maxHeight: 350,
    objectFit: "scale-down",
    cursor: "pointer"
  }
});
const SubImageField = ({ record, classes }) => {
  let childRecord = {
    src: "https://img.orangesoft.co/media/" + record.url
  };
  return record.fileType === "video/mp4" ? (
    <Video src={childRecord.src} className={classes.video} />
  ) : (
    <ImageField source="src" record={childRecord} className={classes.image} />
  );
};
export default React.memo(withStyles(styles)(SubImageField));
