import React from "react";
import { Create, REDUX_FORM_NAME } from "react-admin";
import { change, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { withTranslate } from "ra-core";
import Form from "./Form";

const styles = theme => ({
  form: {
    marginRight: 334,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0
    },
    "& form": {
      paddingBottom: 40
    },
    "& form > div:nth-child(2)": {
      boxShadow:
        "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
      height: 135,
      alignItems: "flex-end",
      borderRadius: 4,
      background: "transparent",
      position: "absolute",
      top: 0,
      right: -337,
      width: 322,
      margin: 0,
      boxSizing: "border-box",
      paddingBottom: 16,
      [theme.breakpoints.down("sm")]: {
        position: "static",
        width: "100%",
        boxShadow: "none",
        paddingTop: 24,
        paddingBottom: 24,
        backgroundColor: "inherit",
        marginTop: 16,
        minHeight: 64,
        boxSizing: "border-box",
        height: "auto",
        borderRadius: 0
      },
      [theme.breakpoints.down("xs")]: {
        padding: 16,
        backgroundColor: "#f5f5f5",
        left: 0,
        right: 0,
        top: "auto",
        width: "100%",
        bottom: "0",
        borderRadius: 0,
        zIndex: 2,
        position: "fixed",
        height: 64
      }
    }
  },
  noLabel: {
    "& label": {
      display: "none"
    }
  },
  labelCategories: {
    "& label": {
      transform: "translate(0, 1.5px)",
      color: "#000 !important"
    }
  },
  author: {
    width: "100%",
    "& label": {
      transform: "translate(0, 24px)",
      color: "#000 !important",
      "& span": {
        width: 60,
        display: "inline-block",
        height: 16,
        overflow: "hidden"
      }
    },
    "& input": {
      paddingLeft: 70
    }
  },
  status: {
    position: "absolute",
    zIndex: "100",
    top: 0,
    right: -276,
    width: 228,
    margin: 0,
    "& label": {
      transform: "translate(0, 24px)",
      color: "#000 !important",
      top: "auto",
      left: "auto"
    },
    "& > div": {
      paddingLeft: 65
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      width: "100%",
      marginTop: 16,
      marginBottom: 8
    }
  },
  addMediaCard: {
    textTransform: "none",
    zIndex: "100",
    position: "absolute",
    top: 150,
    right: -337,
    width: 322,
    margin: 0,
    overflowY: 'auto',
    [theme.breakpoints.down("sm")]: {
      position: "static",
      marginTop: 45,
      marginBottom: 8
    }
  },
});

const ArticlesCreate = ({ classes, values, change, translate, ...props }) => {
  return (
    <Create
      {...props}
      className={classes.form}
      title={translate("ra.pages.articles.create_article_heading")}
    >
      <Form
        change={change}
        classes={classes}
        translate={translate}
        values={values}
      />
    </Create>
  );
};

export default withStyles(styles)(
  withTranslate(
    connect(
      state => ({
        values: getFormValues(REDUX_FORM_NAME)(state)
      }),
      { change }
    )(ArticlesCreate)
  )
);
