import React from "react";
import { withStyles, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { withTranslate } from "react-admin";
const bytesToMb = bytes => {
  const sizeInMb = (bytes / 1024 ** 2).toFixed(2);
  return `${sizeInMb} MB`;
};

const styles = theme => ({
  error: {
    margin: "8px 0",
    fontSize: "0.75rem",
    minHeight: "1em",
    lineHeight: "1em",
    color: theme.palette.error.main
  },
  size: {
    marginLeft: 10,
    display: "inline-block",
    fontSize: "1rem",
    minHeight: "1em",
    lineHeight: "1em"
  },
  uploadFileIcon: {
    fontSize: 20,
    marginRight: 6
  },
  uploaded: {
    borderColor: "#3EC20C",
    color: "#3EC20C",
    "&:hover": {
      borderColor: "#3EC20C"
    }
  }
});
class CustomFileInput extends React.Component {
  state = {
    file: null,
    error: "",
    fileSize: ""
  };

  componentDidMount() {
    const { ndata, record, source } = this.props;
    if (ndata[record.id]) {
      this.setState({
        file: ndata[record.id][source],
        fileSize: ndata[record.id].fileSize
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.file !== nextState.file &&
      this.state.fileSize !== nextState.fileSize
    ) {
      return true;
    }
    return false;
  }

  chooseFile = e => {
    if (e.target.files.length) {
      const { accept, source, record, setNdata, ndata } = this.props;
      let obj = ndata[record.id];
      if (accept && e.target.files[0].type !== accept) {
        this.setState({
          file: null,
          fileSize: "",
          error: `Only ${accept}`
        });
        obj[source] = null;
        obj.fileSize = "";
      } else if (e.target.files[0].size > 10485760) {
        this.setState({
          file: null,
          fileSize: "",
          error: `Max size - ${10}Mb`
        });
        obj[source] = null;
        obj.fileSize = "";
      } else {
        this.setState({
          file: e.target.files[0],
          fileSize: bytesToMb(e.target.files[0].size),
          error: ""
        });
        obj.fileSize = bytesToMb(e.target.files[0].size);
        obj[source] = e.target.files[0];
      }
      obj.touched = true;
      setNdata({ ...ndata, [record.id]: obj });
    }
  };

  render() {
    const { classes, accept, translate } = this.props;
    const { error, fileSize } = this.state;
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          component="label"
          className={fileSize ? classes.uploaded : null}
        >
          {fileSize ? (
            <>
              <svg
                width="14"
                height="12"
                viewBox="0 0 18 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classes.uploadFileIcon}
              >
                <path
                  d="M17.7364 0.263531C17.385 -0.0879608 16.8151 -0.0879608 16.4636 0.263531L5.68106 11.0462L1.53642 6.90156C1.18496 6.55007 0.615147 6.5501 0.263619 6.90156C-0.087873 7.25302 -0.087873 7.82283 0.263619 8.17432L5.04466 12.9553C5.39601 13.3067 5.96624 13.3065 6.31746 12.9553L17.7364 1.53633C18.0879 1.18487 18.0879 0.615024 17.7364 0.263531Z"
                  fill="#3EC20C"
                />
              </svg>{" "}
              {translate("ra.pages.files.actions.uploaded")}
            </>
          ) : (
            <>
              <Add className={classes.uploadFileIcon} />{" "}
              {translate("ra.pages.files.actions.upload")}
            </>
          )}
          <input
            type="file"
            accept={accept}
            onChange={this.chooseFile}
            style={{ display: "none" }}
          />
        </Button>
        {fileSize && <p className={classes.size}>{fileSize}</p>}
        {error && <p className={classes.error}>{error}</p>}
      </>
    );
  }
}
export const FileInput = React.memo(
  withStyles(styles)(withTranslate(CustomFileInput))
);
