import React, { useState, useEffect } from "react";
import {
  SimpleForm,
  TextInput,
  SelectInput,
  REDUX_FORM_NAME,
  ImageInput,
  NumberInput
} from "react-admin";

import { DateTimeInput } from "../../common/DateInput";
import { Button, Dialog, IconButton, Checkbox } from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import { Label } from "../../common/Label";
import MediaListPreview from "../../common/MediaListPreview";
import SelectArrayInput from "../../common/SelectArrayInput";
import { validateWork } from "./validateWork";
import SubImageField from "../../common/SubImageField";
import { OutlinedTextInput } from "../../common/OutlinedTextInput";
import CustomToolbar from "./CustomToolbar";
import { CustomColorInput } from "../../common/ColorInput";
import { services } from "./worksServices";
import classnames from "classnames";
import { choices } from "../../common/statuses";

const IsClickable = ({ change, classes, source, record, translate }) => {
  const [isClickable, setIsClickable] = useState(
    record && record[source] ? record[source] : false
  );
  const handleChange = () => {
    change(REDUX_FORM_NAME, source, !isClickable);
    setIsClickable(!isClickable);
  };
  return (
    <div className={classes.isClickable}>
      <label>{translate("ra.pages.works.fields.case_study")}</label>
      <Checkbox
        checked={isClickable === true}
        onClick={e => handleChange(e)}
        color="primary"
      />
    </div>
  );
};

const FeaturePreview = ({ record, change }) => {
  useEffect(() => {
    if (record && record.preview) {
      change(REDUX_FORM_NAME, "preview", record.preview._id);
      change(REDUX_FORM_NAME, "previewFeature", record.preview);
    }
  }, [change, record.preview]);

  return null;
};

const Form = ({ classes, change, translate, values, ...props }) => {
  const [openPreview, setPreviewOpen] = useState(false);
  const [req, setReq] = useState(false);

  useEffect(() => {
    if (values && values.status === "published") {
      setReq(true);
    } else {
      setReq(false);
    }
  }, [values, setReq]);

  const handleClickPreviewOpen = () => setPreviewOpen(true);
  const handlePreviewClose = () => setPreviewOpen(false);
  const handlePreviewChoose = photo => {
    change(REDUX_FORM_NAME, "previewFeature", photo);
    change(REDUX_FORM_NAME, "preview", photo.id);

    handlePreviewClose();
  };

  const requiredAsterisk = req ? '*' : '';

  const getPriorityList = (maxPriority = 1) => {
    if (props?.record?.status === 'draft') {
      maxPriority += 1;
    }

    return new Array(maxPriority).fill(null).map((el, i) => {
      return {
        id: i + 1,
        name: i + 1
      };
    });
  };

  return (
    <SimpleForm
      {...props}
      toolbar={<CustomToolbar values={values} />}
      validate={validateWork(translate)}
    >
      <TextInput
        source="title"
        fullWidth
        className={classes.noLabel}
        placeholder={translate("ra.pages.works.placeholders.title")}
        InputProps={{
          startAdornment: (
            <Label val={`${translate("ra.pages.works.fields.title")} *`} />
          )
        }}
      />

      {props.record && props.record.id && (
        <TextInput
          source="url"
          fullWidth
          className={classes.noLabel}
          InputProps={{
            startAdornment: (
              <>
                <span className={classes.label}>
                  {`${translate("ra.pages.works.fields.url")} *`}
                </span>
                <span className={classes.orangesortUrl}>
                  https://orangesoft.co/works/
                </span>
              </>
            )
          }}
        />
      )}
      <div className={classes.row}>
        <SelectArrayInput
          fullWidth
          label={`${translate("ra.pages.works.fields.services")} ${requiredAsterisk}`}
          source="services"
          className={classes.labelCategories}
          choices={services}
        />
        <TextInput
          fullWidth
          source="industry"
          className={classes.noLabel}
          placeholder={translate("ra.pages.works.placeholders.industry")}
          InputProps={{
            startAdornment: (
              <Label val={`${translate("ra.pages.works.fields.industry")} ${requiredAsterisk}`} />
            )
          }}
        />
      </div>
      <div className={classes.row}>
        <TextInput
          source="duration"
          fullWidth
          className={classes.noLabel}
          placeholder={translate("ra.pages.works.placeholders.duration")}
          InputProps={{
            startAdornment: (
              <Label val={`${translate("ra.pages.works.fields.duration")} ${requiredAsterisk}`} />
            )
          }}
        />
        <TextInput
          source="teamSize"
          fullWidth
          className={classes.noLabel}
          placeholder={translate("ra.pages.works.placeholders.team")}
          InputProps={{
            startAdornment: (
              <Label val={`${translate("ra.pages.works.fields.teamSize")} ${requiredAsterisk}`} />
            )
          }}
        />
      </div>
      <div className={classes.row}>
        <CustomColorInput
          className={classes.colorWidth}
          source="backgroundColor"
          fullWidth
          values={values}
          change={change}
          record={props.record}
          label={`${translate("ra.pages.works.placeholders.backgroundColor")} ${requiredAsterisk}`}
        />
        <CustomColorInput
          className={classes.colorWidth}
          source="accentColor"
          change={change}
          record={props.record}
          label={`${translate("ra.pages.works.placeholders.accentColor")} ${requiredAsterisk}`}
        />
      </div>

      <OutlinedTextInput
        source="description"
        label={`${translate("ra.pages.works.fields.description")} ${requiredAsterisk}`}
        change={change}
      />

      {props.record && props.record.id && (
        <DateTimeInput
          className={classes.date}
          source="date"
          label={translate("ra.pages.works.fields.date")}
        />
      )}
      <SelectInput
        className={classes.status}
        source="status"
        label={translate("ra.pages.works.fields.status")}
        choices={choices(translate)}
        defaultValue={'draft'}
      />

      {props.record && props.record.id ? (
        <SelectInput
          className={classes.type}
          source="priority"
          label={`${translate("ra.pages.works.fields.priority")} ${requiredAsterisk}`}
          choices={getPriorityList(props.record.maxPossiblePriority)}
          translateChoice={false}
          optionValue="id"
          optionText="name"
        />
      ) : (
        <NumberInput
          className={classes.type}
          source="priority"
          label={`${translate("ra.pages.works.fields.priority")} ${requiredAsterisk}`}
          step={1}
        />
      )}


      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickPreviewOpen}
        className={classes.addMediaButton}
      >
        <Add className={classes.addIcon} />
        {`${translate("ra.pages.works.actions.add_preview_picture")} ${requiredAsterisk}`}
      </Button>

      {openPreview && (
        <Dialog
          maxWidth="sm"
          fullWidth
          open={true}
          onClose={handlePreviewClose}
          aria-labelledby="form-dialog-title"
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handlePreviewClose}
          >
            <Close />
          </IconButton>
          <MediaListPreview
            handleChoose={handlePreviewChoose}
            withVideo={true}
          />
        </Dialog>
      )}

      {props.record && props.record.id && (
        <FeaturePreview source="preview" change={change} />
      )}

      <ImageInput
        accept="image/*"
        className={classes.addMedia}
        source="previewFeature"
      >
        <SubImageField classes={classes} />
      </ImageInput>

    </SimpleForm>
  );
};
export default Form;
