export const validatePreview = translate => values => {
  const requiredArr = value =>
    value && value.length ? undefined : translate("ra.validation.required");
  const validTitle = value =>
    value.match(/^[\sa-zA-Z0-9-]+$/)
      ? undefined
      : translate("ra.validation.only_latin");
  const maxLength = max => value =>
    value.length <= max
      ? undefined
      : translate("ra.validation.maxLength", { max });
  const requiredText = value =>
    value && value.length ? undefined : translate("ra.validation.required");
  const required = translate("ra.validation.required");

  const errors = {};
  //title
  if (!values.title) {
    errors.title = [required];
  } else if (validTitle(values.title)) {
    errors.title = [validTitle(values.title)];
  } else if (maxLength(100)(values.title)) {
    errors.title = [maxLength(100)(values.title)];
  }
  //services
  if (requiredArr(values.services)) {
    errors.services = [requiredArr(values.services)];
  }
  //text
  if (requiredText(values.text)) {
    errors.text = [requiredText(values.text)];
  }
  //previewFeature
  if (!values.previewFeature) {
    errors.previewFeature = [required];
  }

  return errors;
};
