import React from "react";
import { Create } from "react-admin";
import { withStyles } from "@material-ui/core";
import Form from "./Form";
import { change } from "redux-form";
import { connect } from "react-redux";
import { withTranslate } from "ra-core";

const styles = theme => ({
  form: {
    marginRight: 280,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0
    }
  }
});

const AuthorsCreate = ({ classes, change, translate, ...props }) => {
  return (
    <Create
      {...props}
      className={classes.form}
      title={translate("ra.pages.authors.create_author_heading")}
    >
      <Form change={change} />
    </Create>
  );
};

export default connect(
  null,
  { change }
)(withStyles(styles)(withTranslate(AuthorsCreate)));
