import React from "react";
import { List, Datagrid, TextField, ExportButton } from "react-admin";
import { styles } from "../../common/listStyles";
import { withStyles } from "@material-ui/core";
import { json2excel } from "js2excel";
import { CustomDateTimeField } from "../../common/CustomDateField";
import withTranslate from "ra-core/esm/i18n/translate";

let data = {};

const BulkActionButtons = ({ resource, selectedIds }) => {
  const exporter = selectedIds => {
    let exportData = selectedIds
      .map(id => data[id])
      .map(el => {
        delete el.id;
        el.date = new Date(el.date).toLocaleString();
        return el;
      });

    try {
      json2excel({
        data: exportData,
        name: "emailsub-report"
      });
    } catch (e) {
      console.error("export error");
    }
  };
  return (
    <ExportButton resource={resource} onClick={() => exporter(selectedIds)} />
  );
};

const Fiture = props => {
  data = JSON.parse(JSON.stringify(props.data));
  return null;
};

const EmailsList = ({ classes, translate, ...props }) => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={<BulkActionButtons />}
      aside={<Fiture />}
      className={classes.list}
      title={translate("ra.menu.emails")}
    >
      <Datagrid rowClick="edit" className={classes.tableWrapper}>
        <TextField
          label={translate("ra.pages.emails.fields.email")}
          source="email"
          sortable={false}
        />
        <CustomDateTimeField
          label={translate("ra.pages.emails.fields.date")}
          source="date"
          sortable={false}
          classes={classes}
        />
      </Datagrid>
    </List>
  );
};

export default withStyles(styles)(withTranslate(EmailsList));
