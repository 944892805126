import React from "react";
import { Layout, Sidebar } from "react-admin";
import { CustomAppBar } from "./AppBar";

import Menu from "./Menu";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  menu: {
    height: "calc(100vh - 48px)"
  }
});

const CustomSidebar = withStyles(styles)(({ classes, ...props }) => (
  <div className={classes.menu}>
    <Sidebar {...props}>
      <Menu />
    </Sidebar>
  </div>
));

const CustomLayout = props => (
  <Layout {...props} sidebar={CustomSidebar} appBar={CustomAppBar} />
);

export default React.memo(CustomLayout);
