import React from "react";
import { Edit, REDUX_FORM_NAME } from "react-admin";
import { change, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { withTranslate } from "ra-core";
import Form from "./Form";

const styles = theme => {
  return ({
    orangesoftUrl: {
      padding: "6px 0 7px",
      color: "rgba(0, 0, 0, 0.54)"
    },
    form: {
      marginRight: 334,
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        marginRight: 0
      },
      "& form": {
        paddingBottom: 40
      },
      "& form > div:nth-child(2)": {
        boxShadow:
          "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
        height: 166,
        alignItems: "flex-end",
        borderRadius: 4,
        background: "transparent",
        position: "absolute",
        top: 0,
        right: -337,
        width: 322,
        margin: 0,
        boxSizing: "border-box",
        paddingBottom: 16,
        [theme.breakpoints.down("sm")]: {
          position: "static",
          width: "100%",
          boxShadow: "none",
          paddingTop: 24,
          paddingBottom: 24,
          backgroundColor: "inherit",
          marginTop: 16,
          minHeight: 64,
          boxSizing: "border-box",
          height: "auto",
          borderRadius: 0
        },
        [theme.breakpoints.down("xs")]: {
          padding: 16,
          backgroundColor: "#f5f5f5",
          left: 0,
          right: 0,
          top: "auto",
          width: "100%",
          bottom: "0",
          borderRadius: 0,
          zIndex: 2,
          position: "fixed",
          height: 64
        }
      }
    },
    noLabel: {
      "& label": {
        display: "none"
      }
    },
    status: {
      position: "absolute",
      zIndex: "100",
      top: 48,
      right: -318,
      width: 286,
      margin: 0,
      "& label": {
        transform: "translate(0, 24px)",
        color: "#000 !important",
        top: "auto",
        left: "auto"
      },
      "& > div": {
        paddingLeft: 95
      },
      [theme.breakpoints.down("sm")]: {
        position: "static",
        width: "100%",
        marginTop: 16,
        marginBottom: 8
      }
    },
    date: {
      position: "absolute",
      zIndex: "100",
      top: 0,
      right: -318,
      width: 286,
      margin: 0,
      "& label": {
        transform: "translate(0, 24px)",
        color: "#000 !important",
        top: "auto",
        left: "auto"
      },
      "& > div": {
        paddingLeft: 95
      },
      [theme.breakpoints.down("sm")]: {
        position: "static",
        width: "100%",
        marginTop: 16,
        marginBottom: 8
      }
    },
    addMediaCard: {
      textTransform: "none",
      zIndex: "100",
      position: "absolute",
      top: 182,
      right: -337,
      width: 322,
      margin: 0,
      overflowY: 'auto',
      [theme.breakpoints.down("sm")]: {
        position: "static",
        marginTop: 45,
        marginBottom: 8
      }
    },
    label: {
      padding: "6px 20px 7px 0",
      color: "#000"
    },
  });
}

const Title = ({ record, translate }) => {
  return (
    <>
      {`${translate("ra.pages.infographics.edit_heading")} ${record.title}`}
    </>
  );
};

const ArticlesEdit = ({ classes, values, change, translate, ...props }) => {
  return (
    <Edit
      {...props}
      title={<Title translate={translate} />}
      className={classes.form}
    >
      <Form
        change={change}
        classes={classes}
        translate={translate}
        values={values}
      />
    </Edit>
  );
};

export default withStyles(styles)(
  withTranslate(
    connect(
      state => ({
        values: getFormValues(REDUX_FORM_NAME)(state)
      }),
      { change }
    )(ArticlesEdit)
  )
);
