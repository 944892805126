import React from "react";

import { withStyles } from "@material-ui/core";

const styles = theme => ({
  label: {
    padding: "6px 20px 7px 0",
    color: "#000"
  }
});

export const Label = React.memo(
  withStyles(styles)(({ classes, val }) => (
    <span className={classes.label}>{val}</span>
  ))
);
