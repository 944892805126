import React from "react";
import { Route } from "react-router-dom";
import Settings from "./pages/settings";
import Robots from "./pages/robots";
import ArticlePreview from "./pages/articlePreview";
import ArticleCompare from "./pages/articleComparation";
import WorkPreview from "./pages/workPreview";

export default [
  <Route exact path="/settings" component={Settings} />,
  <Route exact path="/robots" component={Robots} />,
  <Route exact path="/article-preview" component={ArticlePreview} noLayout />,
  <Route exact path="/article-compare" component={ArticleCompare} noLayout />,
  <Route exact path="/work-preview" component={WorkPreview} noLayout />
];
