import { customMediaAction } from "../providers/dataProvider";
import { fetchUtils } from "react-admin";
class MyUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    async upload() {
        const file = await this.loader.file

        const data = {
            photo: {
                rawFile: file,
                title: file.name
            },
            name: file.name,
            altName: file.name
        };
        const { url, options } = await customMediaAction(data);
        return fetchUtils
            .fetchJson(url, options)
            .then(res => ({
                default: "https://img.orangesoft.co/media/" + res.json.url
            }))
            .catch(async error => {
                if (error.status === 401) {
                    alert("401");
                }
                return Promise.reject(error);
            });
    }
}

export default function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
    };
}