import React from "react";
import moment from "moment";

import clock from "./clock.svg";
import "./preview.scss";

const Preview = ({ title, author, preview, date, readTime, categories }) => {
  return (
    <section className="article-preview-container">
      <header className="article-header">
        <div className="created-info">
          <span className="category">{`${categories[0].replace(
            "_",
            " "
          )} · ${moment(date).format("DD MMM YYYY")} ·`}</span>
          <img src={clock} alt="Clock" />
          <span className="read-time">{`${readTime} min`}</span>
        </div>
        <h1 className="article-title">{title}</h1>
        <div className="author-preview">
          <img
            src={author.photo}
            alt={`${author.firstName} ${author.lastName}`}
            className="author-avatar"
            loading="eager"
          />
          <div className="author-info">
            <p className="full-name">{`${author.firstName} ${author.lastName}`}</p>
            <p className="job-titile">
              {author.jobTitle}
            </p>
          </div>
        </div>
      </header>
      <figure className="article-hero-image">
        <img
          className="hero-image"
          src={`https://img.orangesoft.co/media/${preview.url}`}
          alt="Hero"
        />
      </figure>
    </section>
  );
};

export default React.memo(Preview);
