import React, { useState, useEffect } from "react";
import { Toolbar, Button, Tabs, Tab, withStyles } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import {
  ImageField,
  SimpleForm,
  ImageInput,
  TextInput,
  showNotification,
  required,
  maxLength
} from "react-admin";
import { Label } from "./Label";
import { connect } from "react-redux";
import { reset } from "redux-form";
import httpClient from "../providers/httpClient";
import Video from "./Video";
import withTranslate from "ra-core/esm/i18n/translate";

const perPage = 12;

const styles = theme => ({
  wrapper: {
    padding: "4px 10px",
    height: "calc(100vh - 188px)",
    overflowY: "auto"
  },
  wrapper2: {
    padding: "4px 10px",
    height: "calc(100vh - 124px)",
    overflowY: "auto",
    '& div[role="toolbar"]': {
      background: "#fff",
      "& button": {
        textTransform: "none",
        width: 85,
        "& svg": {
          display: "none"
        }
      }
    }
  },
  media: {
    margin: 2,
    borderRadius: 4,
    height: 150,
    width: 184,
    display: "inline-block",
    boxSizing: "border-box",
    border: `2px solid transparent`,
    background: "#EEF6FE",
    "&:hover": {
      cursor: "pointer",
      border: `2px solid rgba(0, 0, 0, 0.87)`
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "scale-down"
    },
    "& video": {
      width: "100%",
      height: "100%",
      objectFit: "scale-down",
      cursor: "pointer",
      margin: 0
    }
  },
  right: {
    right: 0,
    position: "absolute"
  },
  left: {
    left: 0,
    position: "absolute"
  },
  tab: {
    color: theme.palette.primary.main
  },
  noLabel: {
    "& label": {
      display: "none"
    }
  },
  addPhoto: {
    marginTop: 40,
    width: 276,
    "& > div": {
      padding: 0,
      "& > span > div:first-child": {
        height: 36,
        padding: 0,
        border: "1px solid rgba(232, 85, 31, 0.5)",
        borderRadius: 4,
        boxSizing: "border-box",
        backgroundColor: "transparent",
        "& p": {
          margin: 0,
          padding: "8px 0",
          color: theme.palette.primary.main
        }
      },
      "& > span > div:first-child:hover": {
        backgroundColor: "rgba(232, 85, 31, 0.08)"
      }
    },
    "& label": {
      display: "none"
    },
    "& svg": {
      fill: theme.palette.primary.main
    }
  },
  image: {
    "& img": {
      width: 276,
      margin: "10px 0",
      borderRadius: 4,
      maxHeight: 350,
      objectFit: "scale-down",
      cursor: "pointer"
    }
  },
  video: {
    margin: "10px 0",
    borderRadius: 4,
    maxHeight: 350,
    objectFit: "scale-down",
    cursor: "pointer"
  }
});

const SubImageField = React.memo(({ record, classes }) => {
  const [childRecord, setChildRecord] = useState({});
  useEffect(() => {
    if (typeof record === "object") {
      setChildRecord({
        src: record.rawFile.preview
      });
    } else
      setChildRecord({
        src: record
      });
  }, [record]);
  return record.rawFile.type === "video/mp4" ? (
    <Video src={record.rawFile.preview} className={classes.video} />
  ) : (
    <ImageField source="src" record={childRecord} className={classes.image} />
  );
});

const MediaGrid = ({ data, classes, handleChoose }) => {
  return (
    <div className={classes.wrapper}>
      {data.map(el => (
        <div
          className={classes.media}
          key={el.id}
          onClick={() => handleChoose(el)}
        >
          {el.fileType === "video/mp4" ? (
            <video>
              <source
                src={"https://img.orangesoft.co/media/" + el.url}
                type="video/mp4"
              />
            </video>
          ) : (
            <img
              key={el.id}
              height="150"
              src={"https://img.orangesoft.co/media/" + el.url}
              alt={el.altName}
            />
          )}
        </div>
      ))}
    </div>
  );
};

const PostPagination = React.memo(
  ({ page, perPage, total, setPage, classes, translate }) => {
    const nbPages = Math.ceil(total / perPage) || 1;
    return (
      nbPages > 1 && (
        <Toolbar>
          {page > 1 && (
            <Button
              color="primary"
              key="prev"
              onClick={() => setPage(page - 1)}
              className={classes.left}
            >
              <ChevronLeft />
              {translate("ra.navigation.prev")}
            </Button>
          )}
          {page !== nbPages && (
            <Button
              className={classes.right}
              color="primary"
              key="next"
              onClick={() => setPage(page + 1)}
              labelPosition="before"
            >
              {translate("ra.navigation.next")}
              <ChevronRight />
            </Button>
          )}
        </Toolbar>
      )
    );
  }
);
const MediaListPreview = ({
  classes,
  handleChoose,
  withVideo,
  translate,
  ...props
}) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    httpClient(
      `/media?limit=${perPage}&offset=${page}${!withVideo && "&type=image"}`,
      {
        method: "GET"
      }
    ).then(res => {
      setTotal(res.headers.get("X-Pagination-Total-Count"), 10);
      setData(res.json);
    });
  }, [page]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSave = ({ photo, name, altName }) => {
    let data = { photo, name, altName };
    const formData = new FormData();
    Object.keys(data).map(el => {
      if (el === "photo") {
        formData.append(el, data[el].rawFile, data[el].title);
      } else {
        formData.append(el, data[el]);
      }
      return el;
    });

    httpClient("/media", {
      method: "POST",
      body: formData
    })
      .then(res => {
        handleChoose(res.json);
        props.reset("media");
      })
      .catch(err => {
        props.showNotification(err.message, "warning");
      });
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={translate("ra.pages.media.list")} className={classes.tab} />
        <Tab
          label={translate("ra.pages.media.add_new")}
          className={classes.tab}
        />
      </Tabs>
      {value === 0 ? (
        <>
          <MediaGrid
            classes={classes}
            handleChoose={handleChoose}
            data={data}
          />
          <PostPagination
            classes={classes}
            page={page}
            perPage={perPage}
            total={total}
            setPage={setPage}
            translate={translate}
          />
        </>
      ) : (
        <div className={classes.wrapper2}>
          <SimpleForm form="media" save={handleSave}>
            <TextInput
              source="name"
              validate={[required(), maxLength(50)]}
              fullWidth
              className={classes.noLabel}
              placeholder={translate("ra.pages.media.placeholders.name")}
              InputProps={{
                startAdornment: (
                  <Label val={translate("ra.pages.media.fields.name") + " *"} />
                )
              }}
            />
            <TextInput
              source="altName"
              validate={[required(), maxLength(50)]}
              fullWidth
              className={classes.noLabel}
              placeholder={translate("ra.pages.media.placeholders.alt_name")}
              InputProps={{
                startAdornment: (
                  <Label
                    val={translate("ra.pages.media.fields.alt_name") + " *"}
                  />
                )
              }}
            />
            <ImageInput
              accept={withVideo ? "image/*, video/mp4" : "image/*"}
              className={classes.addPhoto}
              source="photo"
              validate={[required()]}
              placeholder={
                <p>{translate("ra.pages.media.actions.pick_media") + " *"}</p>
              }
            >
              <SubImageField classes={classes} />
            </ImageInput>
          </SimpleForm>
        </div>
      )}
    </>
  );
};

export default connect(
  null,
  { reset, showNotification }
)(withStyles(styles)(withTranslate(MediaListPreview)));
