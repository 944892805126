import React, { useEffect, useState } from "react";
import Preview from "./Preview/Preview";
import Content from "./Content/Content";
import Author from "./Author/Author";
import httpClient from "../../providers/httpClient";
import "./article.scss";

const Article = props => {
  const [article, setArticle] = useState({});

  useEffect(() => {
    let ar = localStorage.getItem("article-preview");

    if (ar) {
      ar = JSON.parse(ar);

      ar.preview = ar.previewFeature;

      httpClient(`/authors/${ar.author}`, {
        method: "GET"
      })
        .then(res => {
          ar.author = { ...res.json };
          setArticle({ ...ar });
        })
        .catch(err => {
          alert(err);
        });
    }
  }, [props.location.article]);
  return article.text ? (
    <main className="main-blog-container">
      <Preview {...article} />
      <Content content={article.text} />
      <Author author={article.author} />
    </main>
  ) : null;
};

export default Article;
