import React from "react";
import { Edit, REDUX_FORM_NAME } from "react-admin";
import { change, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import withTranslate from "ra-core/esm/i18n/translate";
import Form from "./Form";

const styles = theme => ({
  orangesortUrl: {
    padding: "6px 0 7px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  form: {
    marginRight: 300,
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      marginRight: 0
    },
    "& form": {
      paddingBottom: 40
    },
    "& form > div:nth-child(2)": {
      boxShadow:
        "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
      height: 245,
      alignItems: "flex-end",
      borderRadius: 4,
      background: "transparent",
      position: "absolute",
      top: 0,
      right: -300,
      width: 276,
      margin: 0,
      boxSizing: "border-box",
      paddingBottom: 16,
      [theme.breakpoints.down("sm")]: {
        position: "static",
        width: "100%",
        boxShadow: "none",
        paddingTop: 24,
        paddingBottom: 24,
        backgroundColor: "inherit",
        marginTop: 16,
        minHeight: 64,
        boxSizing: "border-box",
        height: "auto",
        borderRadius: 0
      },
      [theme.breakpoints.down("xs")]: {
        padding: 16,
        backgroundColor: "#f5f5f5",
        left: 0,
        right: 0,
        top: "auto",
        width: "100%",
        bottom: "0",
        borderRadius: 0,
        zIndex: 2,
        position: "fixed",
        height: 64
      }
    }
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      width: "47%"
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      "& > div": {
        width: "100%"
      }
    }
  },
  noLabel: {
    "& label": {
      display: "none"
    }
  },
  labelCategories: {
    "& label": {
      transform: "translate(0, 1.5px)",
      color: "#000 !important"
    }
  },
  author: {
    width: "100%",
    "& label": {
      transform: "translate(0, 24px)",
      color: "#000 !important",
      "& span": {
        width: 50,
        display: "inline-block",
        height: 16,
        overflow: "hidden"
      }
    },
    "& input": {
      paddingLeft: 70
    }
  },
  status: {
    position: "absolute",
    zIndex: "100",
    top: 50,
    right: -276,
    width: 228,
    margin: 0,
    "& label": {
      transform: "translate(0, 24px)",
      color: "#000 !important",
      top: "auto",
      left: "auto"
    },
    "& > div": {
      paddingLeft: 65
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      width: "100%",
      marginTop: 16,
      marginBottom: 8
    }
  },
  type: {
    position: "absolute",
    zIndex: "100",
    top: 115,
    right: -276,
    width: 228,
    margin: 0,
    "& label": {
      transform: "translate(0, 24px)",
      color: "#000 !important",
      top: "auto",
      left: "auto"
    },
    "& > div": {
      paddingLeft: 65
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      width: "100%",
      marginTop: 16,
      marginBottom: 8
    }
  },
  previewResolution: {
    position: "absolute",
    zIndex: "100",
    top: 250,
    right: -300,
    width: 276,
    margin: 0,
    "& label": {
      transform: "translate(0, 24px)",
      color: "#000 !important",
      top: "auto",
      left: "auto"
    },
    "& > div": {
      paddingLeft: 140
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      width: "100%",
      marginTop: 16,
      marginBottom: 8
    }
  },
  date: {
    position: "absolute",
    zIndex: "100",
    top: 0,
    right: -276,
    width: 228,
    margin: 0,
    "& label": {
      transform: "translate(0, 24px)",
      color: "#000 !important",
      top: "auto",
      left: "auto"
    },
    "& > div": {
      paddingLeft: 55
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      width: "100%",
      marginTop: 16,
      marginBottom: 8
    }
  },
  addMediaButton: {
    textTransform: "none",
    zIndex: "100",
    position: "absolute",
    top: 320,
    right: -300,
    width: 276,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      position: "static",
      marginTop: 45,
      marginBottom: 8
    }
  },
  addLargePreviewButton: {
    top: 680
  },
  addPhotoCaseButton: {
    top: 990
  },
  addMedia: {
    position: "absolute",
    top: 350,
    right: -300,
    width: 276,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      position: "static",
      marginTop: -8,
      marginBottom: 0,
      "& p": {
        margin: 0
      }
    },
    "& > div": {
      padding: 0,
      "& > span > div:first-child": {
        visibility: "hidden",
        height: 37,
        boxSizing: "border-box",
        [theme.breakpoints.down("sm")]: {
          height: 0,
          padding: 0
        }
      },
      "& > span > div:first-child:hover": {
        backgroundColor: "rgba(232, 85, 31, 0.08)"
      }
    },
    "& label": {
      display: "none"
    },
    "& button": {
      display: "none"
    },
    "& img": {
      cursor: "auto"
    }
  },
  addLargePreviewPhoto: {
    top: 700
  },
  addCasePhoto: {
    top: 1010
  },
  label: {
    padding: "6px 20px 7px 0",
    color: "#000"
  },
  readtime: {
    fontWeight: 400,
    whiteSpace: "pre",
    position: "absolute",
    top: 125,
    right: -276,
    width: 228,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      position: "static",
      width: "100%",
      marginTop: 40,
      marginBottom: 8
    }
  },
  addIcon: {
    fontSize: 20,
    marginRight: 6
  },
  closeButton: {
    position: "absolute",
    right: 2,
    top: 2,
    color: theme.palette.grey[500],
    zIndex: 100
  }
});

const Title = ({ record, translate }) => {
  return (
    <>{`${translate("ra.pages.works.edit_work_heading")} ${record.title}`}</>
  );
};

const WorksEdit = ({ classes, values, change, translate, ...props }) => {
  return (
    <Edit
      {...props}
      title={<Title translate={translate} />}
      className={classes.form}
    >
      <Form
        change={change}
        classes={classes}
        translate={translate}
        values={values}
      />
    </Edit>
  );
};

export default withStyles(styles)(
  withTranslate(
    connect(
      state => ({
        values: getFormValues(REDUX_FORM_NAME)(state)
      }),
      { change }
    )(WorksEdit)
  )
);
