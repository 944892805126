import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  ReferenceField,
  showNotification,
  TextInput,
  Filter
} from "react-admin";
import { withStyles } from "@material-ui/core";
import DeleteButton from "../../common/DeleteButton";
import { CustomDateTimeField } from "../../common/CustomDateField";
import { styles } from "../../common/listStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Checkbox from "@material-ui/core/Checkbox";
import httpClient from "../../providers/httpClient";
import classnames from "classnames";
import { categories } from "./articlesCategories";
import withTranslate from "ra-core/esm/i18n/translate";
import {connect} from "react-redux";

const ArticlesFilter = (props) => (
  <Filter {...props} className={props.classes.filter}>
      <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const ArticlesActions = ({ basePath, classes, translate }) => (
  <Toolbar className={classes.toolbar}>
    <CreateButton
      basePath={basePath}
      label={translate("ra.pages.articles.actions.add")}
    />
  </Toolbar>
);

const DisplayOnSite = ({ record, handleChange }) => {
  return (
    <Checkbox
      checked={record.status === "published"}
      onClick={e => handleChange(e, record.id)}
      color="primary"
    />
  );
};

const CustomArrayField = ({ record, source, classes, translate }) => {
  return (
    <span className={classnames(classes.tdStyle, classes.capitalize)}>
      {record[source] && record[source].map(el => categories(translate)[el]).join(", ")}
    </span>
  );
};

const CustomCopiableTextField = connect(
  null,
  { showNotification }
)(withTranslate(({record, source, translate, showNotification}) => {
  const value = record[source];

  const handleClick = e => {
    e.stopPropagation();

    const textArea = document.createElement('textarea');
    textArea.value = e.currentTarget.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    showNotification(`${translate("ra.notification.copied")}: ${value}`);
  };

  return (
    <div>
      <span
        title={value}
        onClick={handleClick}
      >
        {value}
      </span>
    </div>
  );
}));

const CustomDatagrid = ({ classes, translate, ...props }) => {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data, setData]);

  const handleChange = async (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      json: { status }
    } = await httpClient(`/articles/${id}`, {
      method: "PUT",
      body: JSON.stringify({
        status: data[id].status === "published" ? "draft" : "published"
      })
    });

    let newData = { ...data };
    newData[id].status = status;
    setData(newData);
  };
  return (
    <Datagrid
      rowClick="edit"
      className={classes.tableWrapper}
      {...props}
      data={data}
    >
      <DisplayOnSite
        label=""
        source="status"
        sortable={false}
        handleChange={handleChange}
      />
      <CustomCopiableTextField
        source="id"
        label={translate("ra.pages.articles.fields.id")}
      />
      <TextField
        source="title"
        sortable={false}
        label={translate("ra.pages.articles.fields.title")}
      />
      <CustomDateTimeField
        label={translate("ra.pages.articles.fields.date")}
        source="date"
        sortable={false}
        classes={classes}
      />
      <CustomArrayField
        label={translate("ra.pages.articles.fields.categories")}
        source="categories"
        sortable={false}
        classes={classes}
        translate={translate}
      />
      <ReferenceField
        label={translate("ra.pages.articles.fields.author")}
        source="author"
        reference="authors"
        sortable={false}
      >
        <TextField source="fullName" />
      </ReferenceField>
      <DeleteButton />
    </Datagrid>
  );
};
const ArticlesList = ({ classes, translate, ...props }) => {
  return (
    <List
      {...props}
      filters={<ArticlesFilter classes={classes} />}
      exporter={false}
      bulkActionButtons={false}
      actions={<ArticlesActions classes={classes} translate={translate} />}
      className={classes.list}
      title={translate("ra.menu.articles")}
      perPage={25}
    >
      <CustomDatagrid classes={classes} translate={translate} />
    </List>
  );
};

export default withStyles(styles)(withTranslate(ArticlesList));
